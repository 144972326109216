import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import { 
    BASE_URL, 
    INSERT_USER_SUBSCRIPTION_TYPE,
    USER_DASHBOARD_DATA
} from "../../../Config/Config"

// Fetch Insert User Subscription Type
const insertUserSubscriptionType = async (data) => {
  const response = await axios.post(
    BASE_URL + INSERT_USER_SUBSCRIPTION_TYPE,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

// Fetch user DashBoard Data
const userDashBoardData = async (data) => {
  const response = await axios.post(
    BASE_URL + USER_DASHBOARD_DATA,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

  const dashboardService = {
    insertUserSubscriptionType,
    userDashBoardData
  }

  export default dashboardService;