import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken, getUser } from "../../Redux/Features/LocalStorage";
import { INDEX, UNAUTHRIZED_USER } from "../Constant";

const ProtectedRoutes = ({ requiredRole = null }) => {

  const isAuth = getToken();
  const currentUser = getUser();

  if (!isAuth) {
    return <Navigate to={INDEX} />;
  }

  if (requiredRole && currentUser?.roleId !== requiredRole) {
    return <Navigate to={UNAUTHRIZED_USER} />;
  }

  return <Outlet />
  
};


export default ProtectedRoutes;
