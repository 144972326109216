import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { addCustomerCardApi, calculateTaxData, showCustomerCardListAPI } from "../../Redux/Features/subscriptionReducer/subscriptionSlice";
import { setSaveCardButtonLoading, setCardVisible } from "../../Redux/Features/stateReducer/stateSlice";

import { Dropdown } from "primereact/dropdown";
import { countriesData } from "../../Utils/constant";
import { getStateByCountryCodeAPI } from "../../Redux/Features/listReducer/listSlice";
import { GetUserDetailsApi } from "../../Redux/Features/authReducer/authSlice";

const SaveCardDetails = ({ type, setCardVisibles, newAmountTax }) => {

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [error, setError] = useState(null);
  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    city: "",
    state: "",
    country: "",
    postal_code: ""
  });

  const { saveCardButtonLoading, subscriptionYearlyItem } = useSelector((state) => state.State);
  const { getStateByCountry: { isLoading } } = useSelector((state) => state.List);
  const { GetUserDetailsData } = useSelector((state) => state.Auth);

  const isBillingCard = type === "billingCard" ? true : false

  const handleCountryChange = (e) => {
    setBillingAddress((prev) => ({
      ...prev,
      country: e.value,  // e.value will contain the selected country's value
    }));
  };

  const handleSubmit = async (event) => {
    dispatch(setSaveCardButtonLoading(true));
    const billingDetails = {
      address: {
        line1: billingAddress.line1,
        city: billingAddress.city,
        state: billingAddress.state,
        country: billingAddress.country,
        postal_code: billingAddress.postal_code,
      },
    };
    try {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });


      const payload = { paymentMethodId: paymentMethod?.id, billingAddress: billingDetails.address };
      if (error) {
        setError(error.message);
        dispatch(setSaveCardButtonLoading(false));
        return;
      } else {
        if (isBillingCard) {
          const ds = await dispatch(addCustomerCardApi(payload)).unwrap()
          await dispatch(showCustomerCardListAPI(payload)).unwrap()
          dispatch(setCardVisibles(false));
        } else {
         const ds = await dispatch(addCustomerCardApi(payload)).unwrap()
  
          if(newAmountTax){
            const newAmount = await dispatch(calculateTaxData({ amount: parseInt(newAmountTax) })).unwrap()
          }
          await dispatch(showCustomerCardListAPI(payload)).unwrap();
          dispatch(setCardVisible(false));
          if (subscriptionYearlyItem?.subType === "yearly") {
            dispatch(setSaveCardButtonLoading(false));
          } else if (GetUserDetailsData?.data?.subscriptionType) {
            dispatch(setCardVisibles(false));
            dispatch(setSaveCardButtonLoading(false));
          }
        }
      }

      if (error) {
        
        setError(error.message);
      } else {
        dispatch(setCardVisible(false));
        dispatch(setSaveCardButtonLoading(false));
      }
    }
    catch (error) {
      dispatch(setSaveCardButtonLoading(false));
    }
  };

  useEffect(() => {
    dispatch(GetUserDetailsApi());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(getStateByCountryCodeAPI({ countryCode: billingAddress?.country })).unwrap();
        if (data.success) {
          setStates(data?.data)
        }
      } catch (error) {
        console.log(error, "error")
      }
    }
    fetchData()
  }, [billingAddress?.country])

  useEffect(() => {
    const sortedCountryData = countriesData.sort((a, b) => {
      if (a.countryName < b.countryName) return -1;
      if (a.countryName > b.countryName) return 1;
      return 0;
    });
    setCountries(sortedCountryData);
  }, []);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Form onSubmit={handleSubmit} style={{ lineHeight: "10px", marginTop: "15px" }}>

          {/* Name fields */}
          <Row className="mb-3">
            <Form.Group controlId="formFirstName" as={Col}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                className="form-control rounded-pill mt-1 color-dark"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formLastName" as={Col}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                className="form-control rounded-pill mt-1 color-dark"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                
              />
            </Form.Group>
          </Row>

          {/* Email field */}
          <Row className="mb-3">
            <Form.Group controlId="formEmail" as={Col}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control rounded-pill mt-1 color-dark"
                required
              />
            </Form.Group>
          </Row>

          {/* Card Information */}
          <Row className="mb-3">
            <Form.Group controlId="formCardNumber" as={Col}>
              <Form.Label>Card Number</Form.Label>
              <CardNumberElement className="form-control rounded-pill mt-1 color-dark" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="formExpiryDate" as={Col}>
              <Form.Label>Expiry Date</Form.Label>
              <CardExpiryElement className="form-control rounded-pill mt-1 color-dark" />
            </Form.Group>
            <Form.Group controlId="formCVC" as={Col}>
              <Form.Label>CVC</Form.Label>
              <CardCvcElement className="form-control rounded-pill mt-1 color-dark" />
            </Form.Group>
          </Row>

          {/* Billing Address */}
          <Row className="mb-3">
            <Form.Group controlId="formBillingAddress" as={Col}>
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address Line 1"
                value={billingAddress.line1}
                onChange={(e) => setBillingAddress({ ...billingAddress, line1: e.target.value })}
                className="form-control rounded-pill mt-1 color-dark"
                required
              />
            </Form.Group>
          </Row>
          <Form.Group controlId="formCountry" as={Col}>
            <Form.Label>Select Country</Form.Label>
            <Dropdown
              value={billingAddress.country}
              className="billing-drop-down mb-3 rounded-pill"
              options={countries}
              onChange={handleCountryChange}
              optionLabel="countryName"
              optionValue="countryCode"
              placeholder="Select a Country"
            />
          </Form.Group>
          <Form.Group controlId="formCountry" as={Col}>
            <Form.Label>Select States</Form.Label>
            <Dropdown
              value={billingAddress.state}
              className="billing-drop-down mb-2 rounded-pill"
              options={states}
              loading={isLoading}
              placeholder="Loading..."
              onChange={(e) => setBillingAddress({ ...billingAddress, state: e.target.value })}
              optionLabel="name"
              optionValue="code"
              placeholder="Select a State"
            />
          </Form.Group>
          <Row className="mb-3 mt-3">
            <Form.Group controlId="formCity" as={Col}>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                value={billingAddress.city}
                onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                className="form-control rounded-pill mt-1 color-dark"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPostalCode" as={Col}>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postal Code"
                value={billingAddress.postal_code}
                onChange={(e) => setBillingAddress({ ...billingAddress, postal_code: e.target.value })}
                className="form-control rounded-pill color-dark mb-2"
                required
              />
            </Form.Group>
          </Row>

          {/* Submit Button */}
          <div className="text-end">
            <Button
              variant="primary"
              type="submit"
              disabled={saveCardButtonLoading}
              className="pt-2"
            >
              {saveCardButtonLoading ? (
                <span
                  className="spinner-border spinner-border-sm me-2 text-dark"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save Card"
              )}
            </Button>
          </div>

          {/* Error Message */}
          {error && (
            <Alert variant="danger" className="mt-2 fs-6 lh-base">
              {error}
            </Alert>
          )}
        </Form>
      </div>

    </>
  );
};

export default SaveCardDetails;
