import { useState, useEffect } from "react";
import {
  CheckHoatawayConnection,
  loginUser,
  reset,
} from "../../../Redux/Features/authReducer/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../../Components/TextError/index";
import { SignInSchema } from "../../../Utils/Validation/Validation";
import { NavLink, useNavigate } from "react-router-dom";
import {
  CONNECTION,
  FORGET_PASSWORD,
  LISTING,
  SIGN_UP,
} from "../../../Routes/Constant";
import { getUser } from "../../../Redux/Features/LocalStorage";
import { ToastContainer } from "react-toastify";
import { Button } from "primereact/button";
import Swal from "sweetalert2";
import GoogleLoginButton from "./GoogleLogin";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const InitialValues = {
    email: "",
    password: "",
  };
  const User = getUser();
  const { loginUserData } = useSelector((state) => state.Auth);
  useEffect(() => {
    if (loginUserData?.data || User) {
      dispatch(reset());
    }
  }, [loginUserData?.data, User, dispatch, Navigate]);

  useEffect(() => {
    if (loginUserData?.roleId == 1 || loginUserData?.roleId == 2) {
      Swal.fire({
        title: "Success",
        text: "User logged in successfully",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
        width: "300px",
        customClass: { popup: "small-swal" },
      });
    }
  }, [loginUserData]);

  const handleSubmit = async (values) => {
    setLoading(true); // Start loading indicator
    const data = {
      email: values.email,
      password: values.password.replace(/\s+/g, ""),
    };
    // resetForm();
    try {
      const res = await dispatch(loginUser(data));
      if (res.error) {
        setLoading(false); // Stop loading indicator on successful response
        return false;
      }

      const { payload } = await dispatch(CheckHoatawayConnection());
      if (payload.validConnection === undefined) {
        Navigate(CONNECTION);
      } else {
        Navigate(LISTING);
      }

      setLoading(false); // Stop loading indicator on successful response
    } catch (error) {
      setLoading(false); // Stop loading indicator on error
    }
  };
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid h-100">
        <section id="login_page" className="h-100">
          <div className="row h-100">
            <div className="col-lg-6 px-0 h-100 d-none d-md-block px-0 h-100">
              <div className="left-box w-100 h-100 text-center position-relative">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo navy-purple.png"
                  }
                  className="  w-25 text-center py-5"
                  alt=""
                />
                <div className="scrnshot w-100">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/client_images/scrnshot/user_dashboard.png"
                    }
                    className="  w-50 "
                    alt=""
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/Assets/images/client_images/scrnshot/user_listings.png"
                    }
                    className="  w-50"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0 h-100">
              <div className="right-box h-100 display-center text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/client_images/horizontal_logo/Logo-white.png"
                  }
                  className="img-fluid w-25 text-center py-5 position-absolute top-0 d-md-none"
                  alt=""
                />
                <div className="inner-box m-auto h-auto">
                  <h2 className="text-white mb-4">Welcome back</h2>
                  <GoogleLoginButton />

                  <Formik
                    initialValues={InitialValues}
                    validationSchema={SignInSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="mb-1">
                          <div className="input-group mb-0 ">
                            <span
                              className="input-group-text bg-transparent border-end-0 input-border"
                              id="basic-addon1"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/images/icons/email.svg"
                                }
                                alt=""
                              />
                            </span>
                            <Field
                              type="email"
                              className="form-control bg-transparent border-start-0 rounded-0 py-3 ps-0 input-border"
                              id="email"
                              name="email"
                              placeholder="E-mail address*"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <ErrorMessage name="email" component={TextError} />
                        <div className="mt-3 mb-1">
                          <div className="input-group mb-0">
                            <span
                              className="input-group-text bg-transparent border-end-0 input-border"
                              id="basic-addon1"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/images/icons/lock.svg"
                                }
                                alt=""
                              />
                            </span>
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control bg-transparent border-start-0 border-end-0 rounded-0 py-3 ps-0 input-border"
                              id="password"
                              name="password"
                              placeholder="Password*"
                              value={values.password.replace(/\s+/g, "")}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={16}
                            />
                            <span>
                              <Button
                                type="button"
                                className={` passEye pi border-start-0  ${showPassword ? "pi-eye-slash" : "pi-eye"
                                  }`}
                                onClick={handlePasswordVisibility}
                              />
                            </span>
                          </div>
                        </div>
                        <ErrorMessage name="password" component={TextError} />
                        <button
                          type="submit"
                          className="btn button-light btn-lg form-control rounded-pill mt-4"
                          disabled={loading} // Disable button while loading
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm me-2 text-dark"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Sign In"
                          )}
                        </button>
                      </Form>
                    )}
                  </Formik>
                  <p className="text-secondary mt-5 mb-2">
                    Don't have an account?
                    <NavLink
                      to={SIGN_UP}
                      className="text-white ms-2 text-decoration-none"
                    >
                      <span>Register</span>
                    </NavLink>
                  </p>
                  <NavLink
                    to={FORGET_PASSWORD}
                    className="text-white pb-4 text-decoration-none"
                  >
                    <span>Forgot Password?</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default SignIn;
