export const passwordFormat = [
  "At least 10 character",
  "At least 1 upper case letter",
  "At least 1 lower case letter",
  "At least 1 number",
  "At least 1 special character",
];

export const countriesData = [
  {
      "countryCode": "GS",
      "countryName": "South Georgia"
  },
  {
      "countryCode": "GD",
      "countryName": "Grenada"
  },
  {
      "countryCode": "CH",
      "countryName": "Switzerland"
  },
  {
      "countryCode": "SL",
      "countryName": "Sierra Leone"
  },
  {
      "countryCode": "HU",
      "countryName": "Hungary"
  },
  {
      "countryCode": "TW",
      "countryName": "Taiwan"
  },
  {
      "countryCode": "WF",
      "countryName": "Wallis and Futuna"
  },
  {
      "countryCode": "BB",
      "countryName": "Barbados"
  },
  {
      "countryCode": "PN",
      "countryName": "Pitcairn Islands"
  },
  {
      "countryCode": "CI",
      "countryName": "Ivory Coast"
  },
  {
      "countryCode": "TN",
      "countryName": "Tunisia"
  },
  {
      "countryCode": "IT",
      "countryName": "Italy"
  },
  {
      "countryCode": "BJ",
      "countryName": "Benin"
  },
  {
      "countryCode": "ID",
      "countryName": "Indonesia"
  },
  {
      "countryCode": "CV",
      "countryName": "Cape Verde"
  },
  {
      "countryCode": "KN",
      "countryName": "Saint Kitts and Nevis"
  },
  {
      "countryCode": "LA",
      "countryName": "Laos"
  },
  {
      "countryCode": "BQ",
      "countryName": "Caribbean Netherlands"
  },
  {
      "countryCode": "UG",
      "countryName": "Uganda"
  },
  {
      "countryCode": "AD",
      "countryName": "Andorra"
  },
  {
      "countryCode": "BI",
      "countryName": "Burundi"
  },
  {
      "countryCode": "ZA",
      "countryName": "South Africa"
  },
  {
      "countryCode": "FR",
      "countryName": "France"
  },
  {
      "countryCode": "LY",
      "countryName": "Libya"
  },
  {
      "countryCode": "MX",
      "countryName": "Mexico"
  },
  {
      "countryCode": "GA",
      "countryName": "Gabon"
  },
  {
      "countryCode": "MP",
      "countryName": "Northern Mariana Islands"
  },
  {
      "countryCode": "MK",
      "countryName": "North Macedonia"
  },
  {
      "countryCode": "CN",
      "countryName": "China"
  },
  {
      "countryCode": "YE",
      "countryName": "Yemen"
  },
  {
      "countryCode": "BL",
      "countryName": "Saint Barthélemy"
  },
  {
      "countryCode": "GG",
      "countryName": "Guernsey"
  },
  {
      "countryCode": "SB",
      "countryName": "Solomon Islands"
  },
  {
      "countryCode": "SJ",
      "countryName": "Svalbard and Jan Mayen"
  },
  {
      "countryCode": "FO",
      "countryName": "Faroe Islands"
  },
  {
      "countryCode": "UZ",
      "countryName": "Uzbekistan"
  },
  {
      "countryCode": "EG",
      "countryName": "Egypt"
  },
  {
      "countryCode": "SN",
      "countryName": "Senegal"
  },
  {
      "countryCode": "LK",
      "countryName": "Sri Lanka"
  },
  {
      "countryCode": "PS",
      "countryName": "Palestine"
  },
  {
      "countryCode": "BD",
      "countryName": "Bangladesh"
  },
  {
      "countryCode": "PE",
      "countryName": "Peru"
  },
  {
      "countryCode": "SG",
      "countryName": "Singapore"
  },
  {
      "countryCode": "TR",
      "countryName": "Turkey"
  },
  {
      "countryCode": "AF",
      "countryName": "Afghanistan"
  },
  {
      "countryCode": "AW",
      "countryName": "Aruba"
  },
  {
      "countryCode": "CK",
      "countryName": "Cook Islands"
  },
  {
      "countryCode": "GB",
      "countryName": "United Kingdom"
  },
  {
      "countryCode": "ZM",
      "countryName": "Zambia"
  },
  {
      "countryCode": "FI",
      "countryName": "Finland"
  },
  {
      "countryCode": "NE",
      "countryName": "Niger"
  },
  {
      "countryCode": "CX",
      "countryName": "Christmas Island"
  },
  {
      "countryCode": "TK",
      "countryName": "Tokelau"
  },
  {
      "countryCode": "GW",
      "countryName": "Guinea-Bissau"
  },
  {
      "countryCode": "AZ",
      "countryName": "Azerbaijan"
  },
  {
      "countryCode": "RE",
      "countryName": "Réunion"
  },
  {
      "countryCode": "DJ",
      "countryName": "Djibouti"
  },
  {
      "countryCode": "KP",
      "countryName": "North Korea"
  },
  {
      "countryCode": "MU",
      "countryName": "Mauritius"
  },
  {
      "countryCode": "MS",
      "countryName": "Montserrat"
  },
  {
      "countryCode": "VI",
      "countryName": "United States Virgin Islands"
  },
  {
      "countryCode": "CO",
      "countryName": "Colombia"
  },
  {
      "countryCode": "GR",
      "countryName": "Greece"
  },
  {
      "countryCode": "HR",
      "countryName": "Croatia"
  },
  {
      "countryCode": "MA",
      "countryName": "Morocco"
  },
  {
      "countryCode": "DZ",
      "countryName": "Algeria"
  },
  {
      "countryCode": "AQ",
      "countryName": "Antarctica"
  },
  {
      "countryCode": "NL",
      "countryName": "Netherlands"
  },
  {
      "countryCode": "SD",
      "countryName": "Sudan"
  },
  {
      "countryCode": "FJ",
      "countryName": "Fiji"
  },
  {
      "countryCode": "LI",
      "countryName": "Liechtenstein"
  },
  {
      "countryCode": "NP",
      "countryName": "Nepal"
  },
  {
      "countryCode": "PR",
      "countryName": "Puerto Rico"
  },
  {
      "countryCode": "GE",
      "countryName": "Georgia"
  },
  {
      "countryCode": "PK",
      "countryName": "Pakistan"
  },
  {
      "countryCode": "MC",
      "countryName": "Monaco"
  },
  {
      "countryCode": "BW",
      "countryName": "Botswana"
  },
  {
      "countryCode": "LB",
      "countryName": "Lebanon"
  },
  {
      "countryCode": "PG",
      "countryName": "Papua New Guinea"
  },
  {
      "countryCode": "YT",
      "countryName": "Mayotte"
  },
  {
      "countryCode": "DO",
      "countryName": "Dominican Republic"
  },
  {
      "countryCode": "NF",
      "countryName": "Norfolk Island"
  },
  {
      "countryCode": "BV",
      "countryName": "Bouvet Island"
  },
  {
      "countryCode": "QA",
      "countryName": "Qatar"
  },
  {
      "countryCode": "MG",
      "countryName": "Madagascar"
  },
  {
      "countryCode": "IN",
      "countryName": "India"
  },
  {
      "countryCode": "SY",
      "countryName": "Syria"
  },
  {
      "countryCode": "ME",
      "countryName": "Montenegro"
  },
  {
      "countryCode": "SZ",
      "countryName": "Eswatini"
  },
  {
      "countryCode": "PY",
      "countryName": "Paraguay"
  },
  {
      "countryCode": "SV",
      "countryName": "El Salvador"
  },
  {
      "countryCode": "UA",
      "countryName": "Ukraine"
  },
  {
      "countryCode": "IM",
      "countryName": "Isle of Man"
  },
  {
      "countryCode": "NA",
      "countryName": "Namibia"
  },
  {
      "countryCode": "AE",
      "countryName": "United Arab Emirates"
  },
  {
      "countryCode": "BG",
      "countryName": "Bulgaria"
  },
  {
      "countryCode": "GL",
      "countryName": "Greenland"
  },
  {
      "countryCode": "DE",
      "countryName": "Germany"
  },
  {
      "countryCode": "KH",
      "countryName": "Cambodia"
  },
  {
      "countryCode": "IQ",
      "countryName": "Iraq"
  },
  {
      "countryCode": "TF",
      "countryName": "French Southern and Antarctic Lands"
  },
  {
      "countryCode": "SE",
      "countryName": "Sweden"
  },
  {
      "countryCode": "CU",
      "countryName": "Cuba"
  },
  {
      "countryCode": "KG",
      "countryName": "Kyrgyzstan"
  },
  {
      "countryCode": "RU",
      "countryName": "Russia"
  },
  {
      "countryCode": "MY",
      "countryName": "Malaysia"
  },
  {
      "countryCode": "ST",
      "countryName": "São Tomé and Príncipe"
  },
  {
      "countryCode": "CY",
      "countryName": "Cyprus"
  },
  {
      "countryCode": "CA",
      "countryName": "Canada"
  },
  {
      "countryCode": "MW",
      "countryName": "Malawi"
  },
  {
      "countryCode": "SA",
      "countryName": "Saudi Arabia"
  },
  {
      "countryCode": "BA",
      "countryName": "Bosnia and Herzegovina"
  },
  {
      "countryCode": "ET",
      "countryName": "Ethiopia"
  },
  {
      "countryCode": "ES",
      "countryName": "Spain"
  },
  {
      "countryCode": "SI",
      "countryName": "Slovenia"
  },
  {
      "countryCode": "OM",
      "countryName": "Oman"
  },
  {
      "countryCode": "PM",
      "countryName": "Saint Pierre and Miquelon"
  },
  {
      "countryCode": "MO",
      "countryName": "Macau"
  },
  {
      "countryCode": "SM",
      "countryName": "San Marino"
  },
  {
      "countryCode": "LS",
      "countryName": "Lesotho"
  },
  {
      "countryCode": "MH",
      "countryName": "Marshall Islands"
  },
  {
      "countryCode": "SX",
      "countryName": "Sint Maarten"
  },
  {
      "countryCode": "IS",
      "countryName": "Iceland"
  },
  {
      "countryCode": "LU",
      "countryName": "Luxembourg"
  },
  {
      "countryCode": "AR",
      "countryName": "Argentina"
  },
  {
      "countryCode": "TC",
      "countryName": "Turks and Caicos Islands"
  },
  {
      "countryCode": "NR",
      "countryName": "Nauru"
  },
  {
      "countryCode": "CC",
      "countryName": "Cocos (Keeling) Islands"
  },
  {
      "countryCode": "EH",
      "countryName": "Western Sahara"
  },
  {
      "countryCode": "DM",
      "countryName": "Dominica"
  },
  {
      "countryCode": "CR",
      "countryName": "Costa Rica"
  },
  {
      "countryCode": "AU",
      "countryName": "Australia"
  },
  {
      "countryCode": "TH",
      "countryName": "Thailand"
  },
  {
      "countryCode": "HT",
      "countryName": "Haiti"
  },
  {
      "countryCode": "TV",
      "countryName": "Tuvalu"
  },
  {
      "countryCode": "HN",
      "countryName": "Honduras"
  },
  {
      "countryCode": "GQ",
      "countryName": "Equatorial Guinea"
  },
  {
      "countryCode": "LC",
      "countryName": "Saint Lucia"
  },
  {
      "countryCode": "PF",
      "countryName": "French Polynesia"
  },
  {
      "countryCode": "BY",
      "countryName": "Belarus"
  },
  {
      "countryCode": "LV",
      "countryName": "Latvia"
  },
  {
      "countryCode": "PW",
      "countryName": "Palau"
  },
  {
      "countryCode": "GP",
      "countryName": "Guadeloupe"
  },
  {
      "countryCode": "PH",
      "countryName": "Philippines"
  },
  {
      "countryCode": "GI",
      "countryName": "Gibraltar"
  },
  {
      "countryCode": "DK",
      "countryName": "Denmark"
  },
  {
      "countryCode": "CM",
      "countryName": "Cameroon"
  },
  {
      "countryCode": "GN",
      "countryName": "Guinea"
  },
  {
      "countryCode": "BH",
      "countryName": "Bahrain"
  },
  {
      "countryCode": "SR",
      "countryName": "Suriname"
  },
  {
      "countryCode": "CD",
      "countryName": "DR Congo"
  },
  {
      "countryCode": "SO",
      "countryName": "Somalia"
  },
  {
      "countryCode": "CZ",
      "countryName": "Czechia"
  },
  {
      "countryCode": "NC",
      "countryName": "New Caledonia"
  },
  {
      "countryCode": "VU",
      "countryName": "Vanuatu"
  },
  {
      "countryCode": "SH",
      "countryName": "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
      "countryCode": "TG",
      "countryName": "Togo"
  },
  {
      "countryCode": "VG",
      "countryName": "British Virgin Islands"
  },
  {
      "countryCode": "KE",
      "countryName": "Kenya"
  },
  {
      "countryCode": "NU",
      "countryName": "Niue"
  },
  {
      "countryCode": "HM",
      "countryName": "Heard Island and McDonald Islands"
  },
  {
      "countryCode": "RW",
      "countryName": "Rwanda"
  },
  {
      "countryCode": "EE",
      "countryName": "Estonia"
  },
  {
      "countryCode": "RO",
      "countryName": "Romania"
  },
  {
      "countryCode": "TT",
      "countryName": "Trinidad and Tobago"
  },
  {
      "countryCode": "GY",
      "countryName": "Guyana"
  },
  {
      "countryCode": "TL",
      "countryName": "Timor-Leste"
  },
  {
      "countryCode": "VN",
      "countryName": "Vietnam"
  },
  {
      "countryCode": "UY",
      "countryName": "Uruguay"
  },
  {
      "countryCode": "VA",
      "countryName": "Vatican City"
  },
  {
      "countryCode": "HK",
      "countryName": "Hong Kong"
  },
  {
      "countryCode": "AT",
      "countryName": "Austria"
  },
  {
      "countryCode": "AG",
      "countryName": "Antigua and Barbuda"
  },
  {
      "countryCode": "TM",
      "countryName": "Turkmenistan"
  },
  {
      "countryCode": "MZ",
      "countryName": "Mozambique"
  },
  {
      "countryCode": "PA",
      "countryName": "Panama"
  },
  {
      "countryCode": "FM",
      "countryName": "Micronesia"
  },
  {
      "countryCode": "IE",
      "countryName": "Ireland"
  },
  {
      "countryCode": "CW",
      "countryName": "Curaçao"
  },
  {
      "countryCode": "GF",
      "countryName": "French Guiana"
  },
  {
      "countryCode": "NO",
      "countryName": "Norway"
  },
  {
      "countryCode": "AX",
      "countryName": "Åland Islands"
  },
  {
      "countryCode": "CF",
      "countryName": "Central African Republic"
  },
  {
      "countryCode": "BF",
      "countryName": "Burkina Faso"
  },
  {
      "countryCode": "ER",
      "countryName": "Eritrea"
  },
  {
      "countryCode": "TZ",
      "countryName": "Tanzania"
  },
  {
      "countryCode": "KR",
      "countryName": "South Korea"
  },
  {
      "countryCode": "JO",
      "countryName": "Jordan"
  },
  {
      "countryCode": "MR",
      "countryName": "Mauritania"
  },
  {
      "countryCode": "LT",
      "countryName": "Lithuania"
  },
  {
      "countryCode": "UM",
      "countryName": "United States Minor Outlying Islands"
  },
  {
      "countryCode": "SK",
      "countryName": "Slovakia"
  },
  {
      "countryCode": "AO",
      "countryName": "Angola"
  },
  {
      "countryCode": "KZ",
      "countryName": "Kazakhstan"
  },
  {
      "countryCode": "MD",
      "countryName": "Moldova"
  },
  {
      "countryCode": "ML",
      "countryName": "Mali"
  },
  {
      "countryCode": "FK",
      "countryName": "Falkland Islands"
  },
  {
      "countryCode": "AM",
      "countryName": "Armenia"
  },
  {
      "countryCode": "WS",
      "countryName": "Samoa"
  },
  {
      "countryCode": "JE",
      "countryName": "Jersey"
  },
  {
      "countryCode": "JP",
      "countryName": "Japan"
  },
  {
      "countryCode": "BO",
      "countryName": "Bolivia"
  },
  {
      "countryCode": "CL",
      "countryName": "Chile"
  },
  {
      "countryCode": "US",
      "countryName": "United States"
  },
  {
      "countryCode": "VC",
      "countryName": "Saint Vincent and the Grenadines"
  },
  {
      "countryCode": "BM",
      "countryName": "Bermuda"
  },
  {
      "countryCode": "SC",
      "countryName": "Seychelles"
  },
  {
      "countryCode": "IO",
      "countryName": "British Indian Ocean Territory"
  },
  {
      "countryCode": "GT",
      "countryName": "Guatemala"
  },
  {
      "countryCode": "EC",
      "countryName": "Ecuador"
  },
  {
      "countryCode": "MQ",
      "countryName": "Martinique"
  },
  {
      "countryCode": "TJ",
      "countryName": "Tajikistan"
  },
  {
      "countryCode": "MT",
      "countryName": "Malta"
  },
  {
      "countryCode": "GM",
      "countryName": "Gambia"
  },
  {
      "countryCode": "NG",
      "countryName": "Nigeria"
  },
  {
      "countryCode": "BS",
      "countryName": "Bahamas"
  },
  {
      "countryCode": "XK",
      "countryName": "Kosovo"
  },
  {
      "countryCode": "KW",
      "countryName": "Kuwait"
  },
  {
      "countryCode": "MV",
      "countryName": "Maldives"
  },
  {
      "countryCode": "SS",
      "countryName": "South Sudan"
  },
  {
      "countryCode": "IR",
      "countryName": "Iran"
  },
  {
      "countryCode": "AL",
      "countryName": "Albania"
  },
  {
      "countryCode": "BR",
      "countryName": "Brazil"
  },
  {
      "countryCode": "RS",
      "countryName": "Serbia"
  },
  {
      "countryCode": "BZ",
      "countryName": "Belize"
  },
  {
      "countryCode": "MM",
      "countryName": "Myanmar"
  },
  {
      "countryCode": "BT",
      "countryName": "Bhutan"
  },
  {
      "countryCode": "VE",
      "countryName": "Venezuela"
  },
  {
      "countryCode": "LR",
      "countryName": "Liberia"
  },
  {
      "countryCode": "JM",
      "countryName": "Jamaica"
  },
  {
      "countryCode": "PL",
      "countryName": "Poland"
  },
  {
      "countryCode": "KY",
      "countryName": "Cayman Islands"
  },
  {
      "countryCode": "BN",
      "countryName": "Brunei"
  },
  {
      "countryCode": "KM",
      "countryName": "Comoros"
  },
  {
      "countryCode": "GU",
      "countryName": "Guam"
  },
  {
      "countryCode": "TO",
      "countryName": "Tonga"
  },
  {
      "countryCode": "KI",
      "countryName": "Kiribati"
  },
  {
      "countryCode": "GH",
      "countryName": "Ghana"
  },
  {
      "countryCode": "TD",
      "countryName": "Chad"
  },
  {
      "countryCode": "ZW",
      "countryName": "Zimbabwe"
  },
  {
      "countryCode": "MF",
      "countryName": "Saint Martin"
  },
  {
      "countryCode": "MN",
      "countryName": "Mongolia"
  },
  {
      "countryCode": "PT",
      "countryName": "Portugal"
  },
  {
      "countryCode": "AS",
      "countryName": "American Samoa"
  },
  {
      "countryCode": "CG",
      "countryName": "Republic of the Congo"
  },
  {
      "countryCode": "BE",
      "countryName": "Belgium"
  },
  {
      "countryCode": "IL",
      "countryName": "Israel"
  },
  {
      "countryCode": "NZ",
      "countryName": "New Zealand"
  },
  {
      "countryCode": "NI",
      "countryName": "Nicaragua"
  },
  {
      "countryCode": "AI",
      "countryName": "Anguilla"
  }
]
