import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import TextError from "../../../Components/TextError/index";
import { EditBillingInformationSchema } from "../../../Utils/Validation/Validation";
import {
  getBillingDetails,
  addAndupdateBillingDetails,
} from "../../../Redux/Features/authReducer/authSlice";
import {
  getCity,
  getAllCountryNpmData,
} from "../../../Redux/Features/listReducer/listSlice";
import { ToastContainer } from "react-toastify";
import "primeicons/primeicons.css";
import SaveCardDetails from "../../payment/SaveCardDetails";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { showCustomerCardListAPI, deleteStripePaymentMethod } from "../../../Redux/Features/subscriptionReducer/subscriptionSlice";

function BillingSetting() {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
  );
  const dispatch = useDispatch();
  const [countryValueOnchange, setCountryValueOnchange] = useState("");
  const [cardVisible, setCardVisible] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);

  const { getBillingDetailsData } = useSelector((state) => state.Auth);
  const { getAllCountryNpmDataData } = useSelector((state) => state.List);
  const { showCustomerCardListAPIData } = useSelector((state) => state.Subscription);

  const BillingSettinginitialValues = {
    companyName: getBillingDetailsData?.Data?.companyName || "",
    billingAddress: getBillingDetailsData?.Data?.billingAddress || "",
    billingCity: getBillingDetailsData?.Data?.billingCity || "",
    billingState: getBillingDetailsData?.Data?.billingState || "",
    billingCountry: getBillingDetailsData?.Data?.billingCountry || "",
    billingPincode: Number(getBillingDetailsData?.Data?.billingPincode) || null,
    companyVatNumber: getBillingDetailsData?.Data?.companyVatNumber || "",
  };

  useEffect(() => {
    dispatch(getBillingDetails());
    dispatch(getAllCountryNpmData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(showCustomerCardListAPI());
  }, [])

  useEffect(() => {
    if (showCustomerCardListAPIData?.cardData?.length > 0 || showCustomerCardListAPIData?.cardData == null) {
      setCardDetails(showCustomerCardListAPIData?.cardData);
    }
  }, [showCustomerCardListAPIData])

  useEffect(() => {
    setCountryValueOnchange(BillingSettinginitialValues?.billingCountry);
  }, [BillingSettinginitialValues?.billingCountry]);



  let timer;
  const saveAddress = (values) => {
    dispatch(addAndupdateBillingDetails(values))
      .unwrap()
      .then((result) => {
        timer = setTimeout(() => {
          dispatch(getBillingDetails());
          clearTimeout(timer);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCity = (selectedCountry) => {
    setCountryValueOnchange(selectedCountry);
    if (selectedCountry !== "") {
      dispatch(getCity({ country: selectedCountry }));
    }
  };

  const deeleteCardConfirm = (data) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-info-circle" style={{ color: "rgb(241, 36, 36)" }}></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Do you want to delete this Card?
          </p>
        </div>

      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => deleteCard(data),
      reject: () => deleteCardRejectBullet(),
    });
  };

  const deleteCardRejectBullet = () => { };

  const deleteCard = async (data) => {
    try {
      if (data) {
        await dispatch(deleteStripePaymentMethod({
          paymentMethodId: data,
        }))
          .unwrap()
          .then((res) => {
            dispatch(showCustomerCardListAPI());
          });
      }
    } catch (error) {
      console.error("Error in deleteCard:", error);
    }
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="white-space-nowrap ">Add Payment Details</span>
    </div>
  );

  return (
    <div
      className="tab-pane fade"
      id="billing"
      role="tabpanel"
      aria-labelledby="billing-tab"
    >
      <Formik
        enableReinitialize={true}
        initialValues={BillingSettinginitialValues}
        validationSchema={EditBillingInformationSchema}
        onSubmit={saveAddress}
      >
        {(props) => {
          return (
            <Form>
              <div className="d-flex align-items-md-center justify-content-between py-3 border-bottom flex-md-row flex-column">
                <div>
                  <h4 className="fs-22 exMedium">Billing Information</h4>
                  <p className="fs-14 text-navy-light exLight mb-2 mb-md-0">
                    View and update your billing informations.
                  </p>
                </div>
                <div>
                  <button
                    className="btn btn-lg button-navy rounded-pill mb-2"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
              <div className="row py-3 border-bottom mx-1">
                <div className="col-md-6 px-0">
                  <label className="exMedium mb-2" htmlFor="compOpt">
                    Company{" "}
                    <span className="color-muted exLight">(optional)</span>
                  </label>
                </div>
                <div className="col-md-6 px-0">
                  <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                    <span
                      className="input-group-text bg-transparent  border-0 ps-3"
                      id="basic-addon1"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_449_2711"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="-1"
                          width="24"
                          height="25"
                        >
                          <rect
                            y="-0.000976562"
                            width="24"
                            height="24"
                            fill="#212529"
                          />
                        </mask>
                        <g mask="url(#mask0_449_2711)"></g>
                        <path
                          d="M19.5 20.75V7H15.75V3.25H4.5V20.75H2V22H10.75V18.25H13.25V22H22V20.75H19.5ZM9.5 15.75H7V13.25H9.5V15.75ZM9.5 12H7V9.5H9.5V12ZM9.5 8.25H7V5.75H9.5V8.25ZM13.25 15.75H10.75V13.25H13.25V15.75ZM13.25 12H10.75V9.5H13.25V12ZM13.25 8.25H10.75V5.75H13.25V8.25ZM18.25 15.75H15.75V13.25H18.25V15.75ZM18.25 12H15.75V9.5H18.25V12Z"
                          fill="#8D909D"
                        />
                      </svg>
                    </span>
                    <Field
                      className=" form-control  border-0 fs-6 ps-0  rounded-pill-end color-dark"
                      type="text"
                      id="companyName"
                      name="companyName"
                      placeholder="Company Name"
                    />
                  </div>
                </div>
              </div>
              <div className="row py-3 border-bottom mx-1">
                <div className="col-md-6 px-0">
                  <label className="exMedium mb-2" htmlFor="location">
                    Billing Details
                  </label>
                </div>
                <div className="col-md-6 px-0">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                        <span
                          className="input-group-text bg-transparent  border-0 ps-3"
                          id="basic-addon1"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_482_1768"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_482_1768)">
                              <path
                                d="M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z"
                                fill="#8D909D"
                              />
                            </g>
                          </svg>
                        </span>
                        <Field
                          className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                          type="text"
                          id="billingAddress"
                          name="billingAddress"
                          placeholder="Address"
                          maxLength={50}
                        />
                      </div>
                      <ErrorMessage
                        name="billingAddress"
                        component={TextError}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                        <span
                          className="input-group-text bg-transparent  border-0 ps-3"
                          id="basic-addon1"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_482_1768"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_482_1768)">
                              <path
                                d="M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z"
                                fill="#8D909D"
                              />
                            </g>
                          </svg>
                        </span>
                        <Field
                          className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                          type="text"
                          id="billingCity"
                          name="billingCity"
                          maxLength={20}
                          placeholder="City"
                        />
                      </div>
                      <ErrorMessage name="billingCity" component={TextError} />
                    </div>         

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="input-group input-48 border position-relative rounded-pill mt-3 mb-1 p-1">
                        <span class="input-group-text bg-transparent border-0 ps-3" id="basic-addon1"
                          style={{ position: "absolute", zIndex: "99" }}

                        ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_482_1768" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="#D9D9D9"></rect></mask><g mask="url(#mask0_482_1768)"><path d="M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z" fill="#8D909D"></path></g></svg></span>
                        <Field style={{ backgroundColor: 'unset' }}
                          name="billingCountry"
                          render={({ field, form }) => (

                            <Select style={{ backgroundColor: 'unset' }}
                              {...field}
                              value={form.values.billingCountry ? { value: form.values.billingCountry, label: form.values.billingCountry } : null}
                              options={[
                                // Custom option
                                { value: '', label: 'Select Country', isDisabled: true },
                                // Existing country options
                                ...(getAllCountryNpmDataData?.data?.map((country) => ({
                                  value: country,
                                  label: country,

                                })) || [])
                              ]}
                              onChange={(option) => {
                                handleCity(option.value);
                                form.setFieldValue(field.name, option.value);
                              }}
                              placeholder="Select Country"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  border: "none",
                                  outline: "none",
                                  boxShadow: "none",
                                  backgroundColor: document.body.classList.contains('dark') ? 'transparent' : 'white', // Change colors based on .dark class
                                }),
                                menu: (baseStyles) => ({
                                  ...baseStyles,
                                  backgroundColor: document.body.classList.contains('dark') ? '#202744' : 'white', // Same for dropdown menu
                                }),
                                option: (baseStyles, { isFocused }) => ({
                                  ...baseStyles,
                                  backgroundColor: isFocused ? '#0d6efd' : (document.body.classList.contains('dark') ? '#202744' : 'white'), // Blue on hover
                                  color: isFocused ? 'white' : (document.body.classList.contains('dark') ? 'white' : 'black'), // White text on hover, adjust for dark mode
                                }),
                              }}
                            />
                          )}
                        />
                        <ErrorMessage name="billingCountry" component={TextError} />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                        <span
                          className="input-group-text bg-transparent  border-0 ps-3"
                          id="basic-addon1"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_482_1768"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_482_1768)">
                              <path
                                d="M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z"
                                fill="#8D909D"
                              />
                            </g>
                          </svg>
                        </span>
                        <Field
                          className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                          type="text"
                          id="billingState"
                          name="billingState"
                          placeholder="State"
                          maxLength={20}
                        />
                      </div>
                      <ErrorMessage name="billingState" component={TextError} />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                        <span
                          className="input-group-text bg-transparent  border-0 ps-3"
                          id="basic-addon1"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_482_1768"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_482_1768)">
                              <path
                                d="M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z"
                                fill="#8D909D"
                              />
                            </g>
                          </svg>
                        </span>
                        <Field
                          className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                          type="text"
                          id="billingPincode"
                          name="billingPincode"
                          placeholder="Zip Code"
                          maxLength={10}
                        />
                      </div>
                      <ErrorMessage
                        name="billingPincode"
                        component={TextError}
                      />
                    </div>
                    {countryValueOnchange !== "United States" ? (
                      <div className="col-md-12">
                        <div className="input-group input-48 border rounded-pill mt-3 mb-1">
                          <span
                            className="input-group-text bg-transparent  border-0 ps-3"
                            id="basic-addon1"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask
                                id="mask0_645_2417"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                              >
                                <rect width="24" height="24" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_645_2417)">
                                <path
                                  d="M14.5 18.5C14.9167 18.5 15.2708 18.3542 15.5625 18.0625C15.8542 17.7708 16 17.4167 16 17C16 16.5833 15.8542 16.2292 15.5625 15.9375C15.2708 15.6458 14.9167 15.5 14.5 15.5C14.0833 15.5 13.7292 15.6458 13.4375 15.9375C13.1458 16.2292 13 16.5833 13 17C13 17.4167 13.1458 17.7708 13.4375 18.0625C13.7292 18.3542 14.0833 18.5 14.5 18.5ZM9.45 18.45L15.95 11.95L14.55 10.55L8.05 17.05L9.45 18.45ZM9.5 13.5C9.91667 13.5 10.2708 13.3542 10.5625 13.0625C10.8542 12.7708 11 12.4167 11 12C11 11.5833 10.8542 11.2292 10.5625 10.9375C10.2708 10.6458 9.91667 10.5 9.5 10.5C9.08333 10.5 8.72917 10.6458 8.4375 10.9375C8.14583 11.2292 8 11.5833 8 12C8 12.4167 8.14583 12.7708 8.4375 13.0625C8.72917 13.3542 9.08333 13.5 9.5 13.5ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9H18L13 4V9Z"
                                  fill="#8D909D"
                                />
                              </g>
                            </svg>
                          </span>
                          <Field
                            className=" form-control  border-0 fs-6 ps-0  rounded-pill-end   color-dark"
                            type="text"
                            id="companyVatNumber"
                            name="companyVatNumber"
                            placeholder="Company Vat Number"
                            maxLength={50}
                          />
                        </div>
                        <ErrorMessage
                          name="companyVatNumber"
                          component={TextError}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <div className="row py-3 border-bottom card-details mx-1">
        <div className="col-md-6 px-0">
          <label className="exMedium mb-2" htmlFor="compEmail">
            Payment Details
          </label>
        </div>

        {/* Show customer card details UI*/}

        <div className="col-md-6">
          {cardDetails?.length > 0 &&
            cardDetails?.map((card) => (
              <div
                className="card mb-3 p-0 border-0 paymentcard-list-outer"
                key={card?.card?.exp_month}
              >
                <div className="card-body  paymentcard-list p-0">
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/Assets/images/credit-card-color-icon.png"
                        }
                        style={{ height: "45px", width: "45px" }}
                        alt="Customr Card"
                      />
                      <div>
                        <h4 className="card-text">
                          {" "}
                          {card?.card?.brand?.toUpperCase()} **** **** ****{" "}
                          {card?.card.last4}{" "}
                        </h4>
                        <p className="card-text mb-0">
                          Expiry {card?.card?.exp_month}/{card?.card?.exp_year}
                        </p>
                      </div>
                    </div>
                    <div className="payact-btn">
                      <span onClick={() => deeleteCardConfirm(card?.id)}>
                        <i className="pi pi-trash p-2 m-1" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="d-lg-flex align-items-center justify-content-between flex-wrap">
            {cardDetails == null &&
              <Button
                className=""
                type="button"
                link
                onClick={() => setCardVisible(true)}
              >
                <div className="text-darkGreen text-nowrap">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/images/icons/add.svg"}
                    className="me-2"
                    alt=""
                  />
                  Add Customer Payment Details
                </div>
              </Button>
            }

            <Dialog
              className="p-3 background-white rounded-17 addpayment-dialog"
              visible={cardVisible}
              
              modal
              header={headerElement}
              style={{ maxWidth: "500px", minWidth: "400px" }}
              onHide={() => {
                if (!cardVisible) return;
                setCardVisible(false);
              }}
            >
              <ToastContainer />
              <Elements stripe={stripePromise}>
                <SaveCardDetails
                  setCardVisibles={setCardVisible}
                  type="billingCard"
                />
              </Elements>
            </Dialog>
          </div>
        </div>
      </div>

      {/* Confirmation dialog to add bullet  */}
      <ConfirmDialog className="toggleOffAlert" />
    </div>
  );
}

export default BillingSetting;
