import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successToast, errorToast } from "../../../Utils/constant/commonFunction";
import couponService from "../couponReducer/couponService";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    createCouponApiData: "",
    getCouponApiData: "",
    updateCouponApiData: "",
    deleteCouponApiData: "",
    sendCouponToUserApiData: "",
    getAllCouponData: "",
    couponAppliedData:"",
    couponApply:{
        isLoading:false,
        isError:null,
        message:"",
        applyCouponCode:""
    }
}

// Create coupon API
export const createCouponApi = createAsyncThunk(
    "createCouponApiData",
    async (data, thunkAPI) => {
        try {
            return await couponService.createCouponApi(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get all coupon API
export const getCouponApi = createAsyncThunk(
    "getCouponApiData",
    async (thunkAPI) => {
        try {
            return await couponService.getCouponApi();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Update Coupon API
export const updateCouponApi = createAsyncThunk(
    "updateCouponApiData",
    async (data, thunkAPI) => {
        try {
            return await couponService.updateCouponApi(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Delete coupon API
export const deleteCouponApi = createAsyncThunk(
    "deleteCouponApiData",
    async (data, thunkAPI) => {
        try {
            return await couponService.deleteCouponApi(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Send  Coupon to Users API
export const sendCouponToUserApi = createAsyncThunk(
    "sendCouponToUserApiData",
    async (data, thunkAPI) => {
        try {
            return await couponService.sendCouponToUserApi(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get all coupon API By User
export const getAllCoupon = createAsyncThunk(
    "getAllCoupon",
    async (thunkAPI) => {
        try {
            return await couponService.getAllCoupon();
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//Coupon Appllied API
export const couponApplied = createAsyncThunk(
    "couponApplied",
    async (data, thunkAPI) => {
        try {
            return await couponService.couponApplied(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
//Coupon Appllied API
export const newCouponAppliedFn = createAsyncThunk(
    "newcouponApplied",
    async (data, thunkAPI) => {
        try {
            return await couponService.newCouponAppliedAPI(data);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const couponSlice = createSlice({
    name: "coupon",
    initialState,
    reducers: {
        reset: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isLoading = false;
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder

            // Create coupon API
            .addCase(createCouponApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createCouponApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.createCouponApiData = payload;
                successToast(payload.message);
            })
            .addCase(createCouponApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })

            // Get all coupon API 
            .addCase(getCouponApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCouponApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.getCouponApiData = payload;
            })
            .addCase(getCouponApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;

            })

            // Update coupon code data 
            .addCase(updateCouponApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateCouponApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.updateCouponApiData = payload;
                successToast(payload.message);
            })
            .addCase(updateCouponApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;

            })

            // Delete Coupon API
            .addCase(deleteCouponApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteCouponApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.deleteCouponApiData = payload;
                successToast(payload.message);
            })
            .addCase(deleteCouponApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })

            // Send Coupon to users
            .addCase(sendCouponToUserApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendCouponToUserApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.sendCouponToUserApiData = payload;
                successToast(payload.message);
            })
            .addCase(sendCouponToUserApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })

            // Get all coupon API
            .addCase(getAllCoupon.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllCoupon.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.getAllCouponData = payload;
            })
            .addCase(getAllCoupon.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;

            })

             // coupon Applied API
             .addCase(couponApplied.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(couponApplied.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.couponAppliedData = payload;
            })
            .addCase(couponApplied.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })
             // coupon Applied API
             .addCase(newCouponAppliedFn.pending, (state) => {
                state.couponApply.isLoading = true;
            })
            .addCase(newCouponAppliedFn.fulfilled, (state, { payload }) => {
                state.couponApply.isError = false;
                state.couponApply.isLoading = false;
                state.couponApply.message = payload.message;
                state.couponApply.applyCouponCode = payload;
            })
            .addCase(newCouponAppliedFn.rejected, (state, { payload }) => {
                state.couponApply.isError = false;
                state.couponApply.isLoading = false;
                state.couponApply.message = payload;
            })
    },
});

export const { reset } = couponSlice.actions;
export default couponSlice.reducer;