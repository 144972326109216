import React from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../Redux/Features/LocalStorage";
import SubscriptionModal from "../SubscriptionModal";
import { setGuestyModalVisible, setHostawayModalVisible } from "../../Redux/Features/stateReducer/stateSlice";
import Guesty from "../Guesty";
import Hostway from "../Hostaway";

function Connection() {
  const userData = getUser();
  const dispatch = useDispatch();

  const { CheckHoatawayConnectionData } = useSelector((state) => state.Auth);

  return (
    <>
      <div id="connection_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <SubscriptionModal />
          <Guesty />
          <Hostway />
          <section id="content-wrapper" class="welcomeuser-container">
            <div className="welcome-user">
            </div>
            <div class="row gy-4">
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="card p-2 ">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/images/hostaway.png"}
                    class="card-img-top img-fluid m-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="text-subtitle1 card-title exMedium">Hostaway</h4>
                    <button className="Powerup-btn btn rounded-pill px-3" onClick={() => dispatch(setHostawayModalVisible(true))}>
                      {CheckHoatawayConnectionData &&
                        CheckHoatawayConnectionData?.status &&
                        CheckHoatawayConnectionData?.validConnection?.find(item => item?.dataSource === 'hostaway')
                        ? `Connected`
                        : `Connection`}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="card p-2 ">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/images/guesty.png"}
                    class="card-img-top img-fluid m-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="text-subtitle1 card-title exMedium">Guesty</h4>
                    <button
                      className="Powerup-btn btn rounded-pill px-3"
                      onClick={() => dispatch(setGuestyModalVisible(true))}
                    >
                      {CheckHoatawayConnectionData?.status &&
                        CheckHoatawayConnectionData?.validConnection?.some(item => item?.dataSource === 'guesty' && item?.success === true)
                        ? `Connected`
                        : CheckHoatawayConnectionData?.validConnection?.some(item => item?.dataSource === 'guesty' && item?.success === false)
                          ? `Reset`
                          : `Connection`
                      }
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6">
                <div class="card p-2 ">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/Assets/images/hostfully.png"
                    }
                    class="card-img-top img-fluid m-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="text-subtitle1 card-title exMedium">
                      Hostfully
                    </h4>
                    <a
                      style={{ textDecoration: "none" }}
                      href="https://www.autorank.com/marketplace/hostfully"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="request-btn btn   rounded-pill px-3">
                        Request Early Access
                      </button>
                    </a>

                  </div>
                  <div class="card-banner position-absolute start-0">
                    <p class=" px-2 py-1 fs-12 exMedium text-purple purple-15">
                      Coming Soon
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pms-outer">
              <div className="pms-inner">
                <h3>1</h3>
                <p>Choose Your PMS provider: Review the available PMS providers displayed on the screen (e.g., Hostaway, Guesty, Hostfully, PriceLabs). Click the "Connection" button next to your selected proider to initiate the connection process.</p>
              </div>

              <div className="pms-inner">
                <h3>2</h3>
                <p>Enter API Credentials: In your property management system, locate Autorank in the marketplace. Gather your API credentials from there, then return to AutoRank and paste them into the appropriate fields.</p>
              </div>

              <div className="pms-inner mb-0">
                <h3>3</h3>
                <p className="mb-0">Add and  Manage Listings: Add your property listings directly through the AutoRank interface. These will  automatically sync with AutoRank for optimized management.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Connection;
