import React, { useState } from 'react';
import { CardCvcElement, CardNumberElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { autoUpdateListing, checkPaymentAboutPaymentId } from '../../Redux/Features/authReducer/authSlice';
import { PAYMENT_URL } from '../../Config/Config';
import { useNavigate } from 'react-router';
import { setPaymentModalClose } from '../../Redux/Features/stateReducer/stateSlice';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const PaymentForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [fname, setFName] = useState('');
    const [lastname, setLastName] = useState('');
    const [country, setCountry] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [cardError, setCardError] = useState({ cvc: '', expiry: '', number: '' });
    const { propertyDetail } = useSelector((state) => state.List);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!fname || !lastname || !country) {
            setError('Please fill in all the required fields.');
            return;
        }

        if (!stripe || !elements) return;

        setError(null);
        setCardError({ cvc: '', expiry: '', number: '' });

        const card = elements.getElement(CardNumberElement);
        const cardCVC = elements.getElement(CardCvcElement);
        const cardExpiry = elements.getElement(CardExpiryElement);

        const cardisEmpty = card._empty;
        const cardCVCIsEmpty = cardCVC._empty;
        const cardExpiryIsEmpty = cardExpiry._empty;

        try {
            if (cardisEmpty || cardCVCIsEmpty || cardExpiryIsEmpty) {
                setError('Please fill in all the required fields.');
                return
            }
            setIsProcessing(true);
            const { data } = await dispatch(
                autoUpdateListing({
                    primaryPropertyId: propertyDetail?.data?.id,
                    redirectUrl: PAYMENT_URL,
                })
            ).unwrap();

            const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(data?.clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: "John Doe",
                        email: 'customer@example.com',
                        phone: '+1234567890',
                        address: {
                            line1: '123 Main Street',
                            city: 'Mumbai',
                            state: 'MH',
                            postal_code: '400001',
                            country: 'IN',
                        },
                    },
                },
            });

            if (stripeError) {
                setCardError({ ...cardError, number: stripeError.message });
                setIsProcessing(false);
            } else {
                if (paymentIntent.status === 'succeeded') {
                    dispatch(checkPaymentAboutPaymentId({ paymentId: paymentIntent?.id })).unwrap()
                    dispatch(setPaymentModalClose());
                    navigate('/payment-success')
                } else {
                    alert('Payment Failed');
                }
                setIsProcessing(false);
            }
        } catch (error) {
            console.log("error", error);
            setError('Payment failed. Please try again.');
            setIsProcessing(false);
        }
    };

    const handleCardChange = (event) => {
        if (event.elementType === 'cardCvc') {
            setCardError({ ...cardError, cvc: event.error ? event.error.message : '' });
        } else if (event.elementType === 'cardExpiry') {
            setCardError({ ...cardError, expiry: event.error ? event.error.message : '' });
        } else if (event.elementType === 'cardNumber') {
            setCardError({ ...cardError, number: event.error ? event.error.message : '' });
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                color: "#666",
                fontSize: "20px",
            },
            invalid: {
                color: "#fa755a",
                fontSize: "20px",
            }
        }
    };

    return (

        <div className="card p-4" style={{ maxWidth: '600px', width: '100%', height: 'auto' }}>
            <h2 className="mb-4 text-center">Payment Form</h2>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col> <div className="form-group">
                        <label htmlFor="fname">First Name</label>
                        <input type="text" className="form-control" id='fname' value={fname} onChange={(e) => setFName(e.target.value)} />
                    </div>
                    </Col>
                    <Col> <div className="form-group">
                        <label htmlFor="lastname">Last Name</label>
                        <input type="text" className="form-control" id='lastname' value={lastname} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    </Col>
                </Row>


                <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <input type="text" className="form-control" id='country' value={country} onChange={(e) => setCountry(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="cardNumber">Card Number</label>
                    <CardNumberElement className="form-control" options={cardElementOptions} onChange={handleCardChange} />
                </div>
                <Row>
                    <Col> <div className="form-group">
                        <label htmlFor="cvc">CVC</label>
                        <CardCvcElement className="form-control" options={cardElementOptions} onChange={handleCardChange} />
                    </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="expiry">Expiry Date</label>
                            <CardExpiryElement className="form-control" options={cardElementOptions} onChange={handleCardChange} />
                        </div>
                    </Col>

                </Row>


                {cardError.number && <div style={{ color: "red" }}>{cardError.number}</div>}
                {cardError.cvc && <div style={{ color: "red" }}>{cardError.cvc}</div>}
                {cardError.expiry && <div style={{ color: "red" }}>{cardError.expiry}</div>}
                {error && <div style={{ color: "red" }}>{error}</div>}
                <Button type='submit' className="btn btn-primary btn-block mt-3" style={{ width: "100%" }}>{isProcessing ? 'Processing' : "Pay Now"}</Button>
            </form>
        </div>
    );
}

export default PaymentForm;
