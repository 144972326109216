import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import logService from "./logService";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  response: null,
  message: "",
  getUpdateLogsData: "",
  getQueueJobLogsData:"",
  getTicketMasterLogsData:""
}

/* Fetch logs Details   */
export const getUpdateLogs = createAsyncThunk(
    "getUpdateLogs",
    async (data, thunkAPI) => {
      try {
        return await logService.getUpdateLogs(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  /* Fetch get QueueJobLogs  API  */
export const getQueueJobLogs = createAsyncThunk(
  "getQueueJobLogs",
  async (data, thunkAPI) => {
    try {
      return await logService.getQueueJobLogs(data);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
)

  /* Fetch get TicketMaster Logs  API  */
  export const getTicketMasterLogs = createAsyncThunk(
    "getTicketMasterLogs",
    async (data, thunkAPI) => {
      try {
        return await logService.getTicketMasterLogs(data);
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  /*  For Slice */
const logSlice = createSlice({
    name: "logs",
    initialState,
    reducers: {
      reset: (state) => {
        state.isSuccess = false;
        state.isError = false;
        state.isLoading = false;
        state.message = "";
        state.response = null
      },
     
    },
    extraReducers: (builder) => {
      builder

      /* Fetch logs Details   */
        .addCase(getUpdateLogs.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getUpdateLogs.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.getUpdateLogsData = payload;        
        })
        .addCase(getUpdateLogs.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })

       /* Fetch get QueueJobLogs  API  */
        .addCase(getQueueJobLogs.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getQueueJobLogs.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.getQueueJobLogsData = payload;        
        })
        .addCase(getQueueJobLogs.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })

        /* Fetch get TicketMaster Logs  API  */
        .addCase(getTicketMasterLogs.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getTicketMasterLogs.fulfilled, (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.isSuccess = true;
          state.response = payload.success;
          state.message = payload.message;
          state.getTicketMasterLogsData = payload;        
        })
        .addCase(getTicketMasterLogs.rejected, (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.user = null;
          state.isSuccess = false;
          state.response = false;
          state.message = payload.message;        
        })

    },
});

export const { reset } = logSlice.actions;
export default logSlice.reducer;