import Cookies from 'js-cookie';
/**
 * 
 * @param {*} token | take token from api 
 * @returns send to local storage
 */
export const setToken = (token) => {
  return Cookies.set("otamiserToken", token);
};

export const getToken = () => {
  return Cookies.get("otamiserToken")
};

export const setToken2 = (token) => {
  return Cookies.set("otamiserToken2", token);
};

export const getToken2 = () => {
  return Cookies.get("otamiserToken2");
};

/**
 * 
 * @param {take as pure userData} userData 
 * @returns json data
 */
export const setUser = (userData) => {
  return localStorage.setItem("otamiserUser", JSON.stringify(userData));
};

export const getUser = () => {
  const user = localStorage.getItem("otamiserUser");
  if (!user) return null;

  try {
    return JSON.parse(user);
  } catch (e) {
    console.error("Error parsing user data:", e);
    return null;
  }
};
export const setEmail = (forgotEmail) => {
  return localStorage.setItem("otamiserUserEmail", JSON.stringify(forgotEmail));
};
export const getEmail = () => {
  return JSON.parse(localStorage.getItem("otamiserUserEmail"));
};
export const setSubsId = (subsId) => {
  return localStorage.setItem("subsid", JSON.stringify(subsId));
};
export const getSubsId = () => {
  return JSON.parse(localStorage.getItem("subsid"));
};
export const removeSubsId = () => {
  return localStorage.removeItem("subsid");
};
export const setPaymentId = (paymentId) => {
  return localStorage.setItem("paymentid", JSON.stringify(paymentId));
};
export const getPaymentId = () => {
  return JSON.parse(localStorage.getItem("paymentid"));
};
export const removePaymentId = () => {
  return localStorage.removeItem("paymentid");
};
