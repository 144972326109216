import React, { useEffect, useState } from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserListApi,
  inActivateUser,
  makeAdminImpersonateUser,
  adminIncressesLimitOfListing,
  makeStopFreeTrial,
} from "../../Redux/Features/authReducer/authSlice";
import {
  HostawayLIstings,
  CheckHoatawayConnection,
} from "../../Redux/Features/authReducer/authSlice";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DateFormat } from "../../Utils/constant/commonFunction";
import Swal from "sweetalert2";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../Components/TextError/index";
import { PropertyLimitSchema } from "../../Utils/Validation/Validation";
import { Tooltip } from 'primereact/tooltip';
import ProgressLoader from "../../Components/loader/ProgressLoader";

function UserList() {
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [showIncreaseModal, setShowIncreaseModal] = useState(false);
  const [handleIncrease, sethandleIncreaseLimit] = useState();
  const [propertyLimit, setPropertyLimit] = useState('');
  const [user, setUser] = useState(localStorage.getItem("ImpersonateUser"));

  const ListingLimitInitialValues = {
    propertyLimit: handleIncrease?.propertyLimit || 0,
  };

  const { GetUserListData, inActiveUserData, getUserListHandle } =
    useSelector((state) => state.Auth);

  // Effect to set the initial value from propertyDetails
  useEffect(() => {
    if (handleIncrease && handleIncrease?.propertyLimit) {
      setPropertyLimit(handleIncrease?.propertyLimit);
    }
  }, [handleIncrease]);

  useEffect(() => {
    dispatch(GetUserListApi());
  }, [inActiveUserData]);

  const DisableFreeTrialConfirm = (rowData) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">  <i className="pi pi-exclamation-triangle"></i> &nbsp; {rowData?.isFreeTrial ? "Disable Free Trail" : "Enable Free Trial"}</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            {rowData?.isFreeTrial ? ' Are you sure you want to Disable Free Trail?' : ' Are you sure you want to Enable Free Trial?'}
          </p>
        </div>

      ),
      closable: false, // Add this line to prevent closing the dialog
      // dismissableMask: true, // Allow clicking outside to close
      defaultFocus: "accept",
      accept: () => handleStopFreeTrial(rowData),
      reject: () => disableFreeListingRejectBullet(),
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const handleStopFreeTrial = async (rowData) => {
    try {
      const result = await dispatch(makeStopFreeTrial({ userId: rowData?.id })).unwrap();
      if (result?.success) {
        dispatch(GetUserListApi())
      }
    } catch (error) {
      console.log(error, "error")
    }
  }

  const disableFreeListingRejectBullet = () => { };

  const impersonateUserByAdmin = (rowData) => {
    localStorage.setItem("ImpersonateUser", rowData?.fname);
    dispatch(makeAdminImpersonateUser({ inpersonateUserId: rowData?.id }))
      .unwrap()
      .then((result) => {
        dispatch(CheckHoatawayConnection());
        dispatch(HostawayLIstings());
      })
    Swal.fire({
      title: "Success",
      text: "Admin impersonated successfully",
      icon: "success",
      timer: 1300,
      showConfirmButton: false,
      width: "300px",
      customClass: { popup: "small-swal" },
    });
    setUser(localStorage.getItem("ImpersonateUser"));
  };

  const handleIncreaseLimit = (rowData) => {
    setShowIncreaseModal(true);
    sethandleIncreaseLimit(rowData)
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Check if the value is a number and has a maximum length of 4
    if (/^\d{0,3}$/.test(value)) {
      setPropertyLimit(value);
    }
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2 mb-3">
      <span className="font-bold white-space-nowrap ">Set Property Listing Limit</span>
    </div>
  );

  const handleSaveLimit = async (values) => {
    try {
      await dispatch(adminIncressesLimitOfListing({ userId: handleIncrease?.id, propertyLimit: propertyLimit }))
        .unwrap()
        .then((result) => {
          if (result?.success) {
            dispatch(GetUserListApi());
            setShowIncreaseModal(false);
          }
        })
    }
    catch (error) {
      console.error(error);
      setShowIncreaseModal(false);
    }
  }

  const DisableUserConfirm = (rowData) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">  <i className="pi pi-exclamation-triangle"></i> &nbsp; {rowData?.status === "inactive" ? 'Confirm Enable Account' : 'Confirm Disable Account'}</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            {rowData?.status === "inactive" ? ' Are you sure you want to enable this account?' : ' Are you sure you want to disable this account?'}
          </p>
        </div>

      ),
      closable: false, // Add this line to prevent closing the dialog
      // dismissableMask: true, // Allow clicking outside to close

      defaultFocus: "accept",
      accept: () => disableAccount(rowData),
      reject: () => disableAccountRejectBullet(),
      acceptLabel: "Confirm",
      rejectLabel: "Cancel"
    });
  };

  const disableAccount = async (rowData) => {
    dispatch(
      inActivateUser({
        userId: rowData.id,
        userEmail: rowData.email,
        userStatus: rowData?.status === "active" ? "inactive" : "active",
      })
    );
  };
  const disableAccountRejectBullet = () => { };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          className="rounded-circle"
          icon="pi pi-users fs-5"
          tooltip="Impersonate User"
          tooltipOptions={{ position: "top" }}
          rounded
          outlined
          severity="secondary"
          onClick={() => impersonateUserByAdmin(rowData)}
        />

        {
          rowData?.isFreeTrial && (
            <Button
              className="rounded-circle"
              icon={rowData?.isFreeTrial ? "pi pi-times-circle" : "pi pi-plus-circle"}
              tooltip={rowData?.isFreeTrial ? "Disable Free Trail" : "Enable Free Trial"}
              tooltipOptions={{ position: "top" }}
              rounded
              outlined
              severity="secondary"
              onClick={() => DisableFreeTrialConfirm(rowData)}
            />
          )}
        <Button
          className="rounded-circle"
          icon="pi pi-plus fs-5"
          tooltip="Increase Limit"
          tooltipOptions={{ position: "top" }}
          rounded
          outlined
          severity="secondary"
          onClick={() => handleIncreaseLimit(rowData)}
        />


        <div className="user-icons" onClick={() => DisableUserConfirm(rowData)} >
          <Tooltip
            target=".custom-target-icon"
            position="top"
            style={{ marginTop: '48px !important' }}
          />


          <span
            className="custom-target-icon"
            data-pr-tooltip={rowData?.status === "inactive" ? 'Enable' : 'Disable'}
            data-pr-position="top"
            data-pr-at="center top"
            style={{ marginTop: '25px' }}
          >
            <i className="pi pi-user fs-5 p-button-icon-only" ></i>
            <i className={`pi ${rowData?.status === "inactive" ? 'pi-check' : 'pi-times'} status-icon`}></i>
          </span>
        </div>

      </React.Fragment>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <span
            className={`  fw-light rounded-pill fs-14 px-3 py-2 exMono tags mb-0 ${rowData?.status === "active"
              ? "mint-30"
              :
              "red-10"
              }`}
          >
            {rowData?.status?.charAt(0)?.toUpperCase() + rowData?.status?.slice(1) || "N/A"}
          </span>
        </div>
      </>
    )
  }

  const clearImpersonateData = () => {
    Swal.fire({
      title: "Success",
      text: "Admin access removed successfully.",
      icon: "success",
      timer: 3000,
      showConfirmButton: false,
      width: "300px",
      customClass: { popup: "small-swal" },
    });

    Cookies.remove("otamiserToken2");
    localStorage.removeItem("ImpersonateUser");

    // Update the state to reflect the changes
    setUser(null);
  };

  return (
    <>
      <div id="connection_page">
        <Header />
        <div id="wrapper">
          <SideBar />
          <section id="content-wrapper">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className=" content-title mb-0 exDemiBold mb-2">User List</h2>

              {user !== null && (
                <>
                  <div className="d-flex gap-2" style={{ alignItems: 'baseline' }}>
                    <button className="removeAccess" onClick={clearImpersonateData}>Remove Access</button>
                
                    <h5 style={{ minWidth: '100%' }}>Impersonated User: {user}</h5>
                  </div>
                </>
              )}

            </div>
            {
              getUserListHandle?.isLoading ? (
                <ProgressLoader />
              ) :
                <DataTable
                  className="shadow-sm rounded-17"
                  header={header}
                  value={GetUserListData?.data || []}
                  selection={selectedProducts}
                  onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id"
                  tableStyle={{ minWidth: "50rem" }}
                  responsiveLayout="scroll"
                  size="small"
                  showGridlines
                  stripedRows
                  removableSort
                  paginator
                  rows={5}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  filters={filters}
                >
                  <Column field="fname" header="First Name" sortable></Column>
                  <Column field="lname" header="Last Name" sortable></Column>
                  <Column field="email" header="Email Address" sortable></Column>
                  <Column field="phoneNumber" header="Phone Number" sortable></Column>
                  <Column
                    field="status"
                    header="Status"
                    sortable
                    body={statusBodyTemplate}
                  ></Column>
                  <Column
                    field="createdAt"
                    header="Create Date"
                    sortable
                    body={(row) => <span>{DateFormat(row?.createdAt)}</span>}
                  ></Column>
                  <Column className="d-flex align-items-center gap-1" header="Actions" body={actionBodyTemplate}></Column>
                </DataTable>
            }
            <Dialog
              className="p-3 background-white rounded-17"
              visible={showIncreaseModal}
              modal
              header={headerElement}
              style={{ maxWidth: "500px", minWidth: "400px" }}
              onHide={() => {
                if (!showIncreaseModal) return;
                setShowIncreaseModal(false);
              }}
            >
              <Formik
                enableReinitialize={true}
                initialValues={ListingLimitInitialValues}
                validationSchema={PropertyLimitSchema}
                onSubmit={handleSaveLimit}
              >
                <Form className="px-2">
                  <div className="mb-3">
                    <label htmlFor="name">Number of Listing</label>
                    <Field
                      className="form-control rounded-pill mt-1 color-dark"
                      type="number"
                      id="propertyLimit"
                      name="propertyLimit"
                      min={0}
                      step={1}
                      value={propertyLimit}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="propertyLimit" component={TextError} />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg button-navy  form-control rounded-pill mt-3"
                  >
                    Save
                  </button>
                </Form>
              </Formik>
            </Dialog>
          </section>
        </div>
      </div>
      <ConfirmDialog className="toggleOffAlert" />
    </>
  );
}

export default UserList;
