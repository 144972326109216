import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import subscriptionService from "./subscriptionService";
import { successToast, errorToast } from "../../../Utils/constant/commonFunction";

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    subscriptionData: "",
    buySubsData: "",
    checkPayStatusData: "",
    deleteStripePaymentMethodData: "",
    addCustomerCardApiData: "",
    showCustomerCardListAPIData:"",
    calculateTaxAmount:{
        taxAmount:"",
        isLoading:false,
        isError:null,
        message:"",
        isSuccess:""
    },
}

/* Fetch subscription Details   */
export const fetchSubscriptionApi = createAsyncThunk(
    "getSubscriptionsData",
    async (_, thunkAPI) => {
        try {
            return await subscriptionService.fetchSubscriptionApi();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Buy subscription   */
export const buySubscriptionApi = createAsyncThunk(
    "buySubscriptions",
    async (data, thunkAPI) => {
        try {
            return await subscriptionService.buySubscriptionApi(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Add payment card   */
export const checkPaymentStatusApi = createAsyncThunk(
    "checkPaymentStatus",
    async (data, thunkAPI) => {
        try {
            return await subscriptionService.checkPaymentStatusApi(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete Customer Card for Payment
export const deleteStripePaymentMethod = createAsyncThunk(
    "deleteStripePaymentMethod",
    async (data, thunkAPI) => {
        try {
            return await subscriptionService.deleteStripePaymentMethod(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)


// Add Customer Card for Payment
export const addCustomerCardApi = createAsyncThunk(
    "addCustomerCardApi",
    async (data, thunkAPI) => {
        try {
            return await subscriptionService.addCustomerCardApi(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            errorToast(message);
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/* Fetch Card Details   */
export const showCustomerCardListAPI = createAsyncThunk(
    "showCustomerCardListAPI",
    async (_, thunkAPI) => {
        try {
            return await subscriptionService.showCustomerCardListAPI();
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Calculate the tax
export const calculateTaxData = createAsyncThunk(
    "/calculateTax",
    async (data, thunkAPI) => {
        try {
            return await subscriptionService.calculateTaxAPI(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)


/*  For Slice */
const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        reset: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isLoading = false;
            state.message = "";
        },
        deleteTempTax :(state,{payload})=>{
                state.calculateTaxAmount.taxAmount = ""
        }

    },
    extraReducers: (builder) => {
        builder
            // Fetch subscription
            .addCase(fetchSubscriptionApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSubscriptionApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.subscriptionData = payload;
            })
            .addCase(fetchSubscriptionApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

            // Buy Subscription
            .addCase(buySubscriptionApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(buySubscriptionApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.buySubsData = payload;
            })
            .addCase(buySubscriptionApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

            // Add payment card
            .addCase(checkPaymentStatusApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(checkPaymentStatusApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.checkPayStatusData = payload;
            })
            .addCase(checkPaymentStatusApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

            // Delete Customer Card for Payment
            .addCase(deleteStripePaymentMethod.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteStripePaymentMethod.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.deleteStripePaymentMethodData = payload;
            })
            .addCase(deleteStripePaymentMethod.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

              // Add Customer Card for Payment
              .addCase(addCustomerCardApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addCustomerCardApi.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload.message;
                state.addCustomerCardApiData = payload;
                successToast(payload.message);
            })
            .addCase(addCustomerCardApi.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload;
            })

            // Fetch Card Details
            .addCase(showCustomerCardListAPI.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(showCustomerCardListAPI.fulfilled, (state, { payload }) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = payload?.message;
                state.showCustomerCardListAPIData = payload;
            })
            .addCase(showCustomerCardListAPI.rejected, (state, { payload }) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = payload.message;
            })

            // Claculate Details
            .addCase(calculateTaxData.pending, (state) => {
                state.calculateTaxAmount.isLoading = true;
            })
            .addCase(calculateTaxData.fulfilled, (state, { payload }) => {
                state.calculateTaxAmount.isError = false;
                state.calculateTaxAmount.isLoading = false;
                state.calculateTaxAmount.isSuccess = true;
                state.calculateTaxAmount.message = payload?.message;
                state.calculateTaxAmount.taxAmount = payload;
            })
            .addCase(calculateTaxData.rejected, (state, { payload }) => {
                state.calculateTaxAmount.isError = true;
                state.calculateTaxAmount.isLoading = false;
                state.calculateTaxAmount.isSuccess = false;
                state.calculateTaxAmount.message = payload?.message;
            })

    },
});

export const { reset,deleteTempTax } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;