import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubscriptionModalVisible, setAutomationModalVisible,
  setExpiredListing, setFreeProperty,
  setVisible, setSelectedProperties, setIsPropertyListingLoader, setSubscriptionSelectPlan,
  setCardVisible,
  setSubscriptionYearlyData,
  setChecked,
  setIsPaymentLoading,
  setDisabledSidebarButton
} from "../../Redux/Features/stateReducer/stateSlice";
import { HostawayLIstings, GetUserDetailsApi } from "../../Redux/Features/authReducer/authSlice";
import { getAllCountryNpmData, setSelectedUserSubscriptionStatus, subscriptionPaymentAPI } from "../../Redux/Features/listReducer/listSlice";
import { couponApplied, getAllCoupon, newCouponAppliedFn } from "../../Redux/Features/couponReducer/couponSlice";
import { showCustomerCardListAPI, deleteStripePaymentMethod, calculateTaxData, deleteTempTax } from "../../Redux/Features/subscriptionReducer/subscriptionSlice";
import { insertUserSubscriptionType } from "../../Redux/Features/dashboard/dashboardSlice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import SaveCardDetails from "../payment/SaveCardDetails";
import { loadStripe } from "@stripe/stripe-js";
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";
import { LISTING } from "../../Routes/Constant";
import { Spinner } from "react-bootstrap";
import { handleSubscriptionType } from "../../Redux/Features/commonStateSlice/commonStateSlice";


const SubscriptionPaymentModal = (props) => {

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
  );
  const navigate = useNavigate();
  const { selectedOrder, selectedOrderrecordId, getAllCouponData, setSelectedRanks, setHostingList, hostingList } = props;

  const dispatch = useDispatch();
  const [toggleMY, settoggleMY] = useState(false);
  const [isApplied, setIsApplied] = useState("");
  const [isshow, setIsShow] = useState(false);
  const [isPaymentStatus, setIsPaymentStatus] = useState(false);
  const [selectedUserSubscriptionType, setSelectedUserSubscriptionType] = useState("");

  const [couponId, setCouponId] = useState("");
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [subscriptionPrice, setSubscriptionPrice] = useState(0);
  const [cardDetails, setCardDetails] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [offerData, setOfferData] = useState("");
  const [primaryIdSubscription, setPrimaryIdSubscription] = useState([]);
  const [listData, setListData] = useState([]);


  const { subscriptionModalVisible, selectedProperties, checked, subscriptionYearlyItem, freeProperty, expiredListing, subscriptionSelectPlan, cardVisible, isPaymentLoading } = useSelector((state) => state?.State);
  const { autoUnautoPropertyListingData, getAllCountryNpmDataData, autoUnautoProperty } = useSelector((state) => state.List);

  const { couponAppliedData, couponApply } = useSelector((state) => state.Coupon)
  const { showCustomerCardListAPIData, calculateTaxAmount: { taxAmount, isLoading }, deleteStripePaymentMethodData, addCustomerCardApiData } = useSelector((state) => state.Subscription);
  const { GetUserDetailsData, HostawayLIstingsData } = useSelector((state) => state.Auth);
  const settingSubscriptionYearlyPlan = subscriptionYearlyItem?.subType === "yearly";
  const cardLength = showCustomerCardListAPIData?.cardData?.length > 0
  const mainCost = settingSubscriptionYearlyPlan
    ? subscriptionYearlyItem?.data?.yearlyPrice
    : selectedUserSubscriptionType === "monthly"
      ? autoUnautoPropertyListingData?.data?.monthlyPrice
      : autoUnautoPropertyListingData?.data?.yearlyPrice

  const mainConstAmount = parseFloat(mainCost).toFixed(2)

  const taxAmountExclusive = parseFloat(taxAmount?.taxAmountExclusive).toFixed(2)

  const offerDataAmount = parseFloat(offerData?.discountPrice).toFixed(2)

  const mainSubType = settingSubscriptionYearlyPlan ? subscriptionYearlyItem?.subType : selectedUserSubscriptionType
  const newAmountTax = couponApply.applyCouponCode?.success ? offerData?.remainingAmount : mainConstAmount

  const finalAmountForTaxWithCoupon = (parseFloat(mainConstAmount) + parseFloat(taxAmountExclusive) - parseFloat(offerDataAmount)).toFixed(2)
  const finalAmountForCoupon = (parseFloat(mainConstAmount) - parseFloat(offerDataAmount)).toFixed(2)
  const finalAmountForTax = (parseFloat(mainConstAmount) + parseFloat(taxAmountExclusive)).toFixed(2);


  // Event handler function
  const handleTabClick = async (isYearly, subscriptionType) => {
    try {
      if (cardLength) {
        const resultTax = await dispatch(calculateTaxData({ amount: subscriptionType === "monthly" ? parseInt(autoUnautoPropertyListingData?.data?.monthlyPrice) : parseInt(autoUnautoPropertyListingData?.data?.yearlyPrice) })).unwrap();
      }
    } catch (error) {
      console.log(error)
    }


    settoggleMY(isYearly);
    setSelectedUserSubscriptionType(subscriptionType);
    dispatch(handleSubscriptionType(subscriptionType))
    setOfferData("");
    setCouponCode("");
  }


  useEffect(() => {
    if (autoUnautoPropertyListingData?.primaryListingsIdsForSubscription?.length > 0) {
      setPrimaryIdSubscription(
        hostingList.filter(item =>
          autoUnautoPropertyListingData.primaryListingsIdsForSubscription.includes(item.id)
        )
      );
    }
  }, [autoUnautoPropertyListingData?.primaryListingsIdsForSubscription]);


  useEffect(() => {
    if (autoUnautoPropertyListingData?.data) {
      const { yearlyPrice, monthlyPrice } = autoUnautoPropertyListingData?.data;

      // Check if subscription is "yearly" based on either the subscriptionYearlyItem or selectedUserSubscriptionType
      if (settingSubscriptionYearlyPlan || selectedUserSubscriptionType === "yearly") {
        setSubscriptionPrice(yearlyPrice);
      } else {
        setSubscriptionPrice(monthlyPrice);
      }
    }
  }, [autoUnautoPropertyListingData, selectedUserSubscriptionType, settingSubscriptionYearlyPlan]);

  useEffect(() => {
    if (GetUserDetailsData?.data) {
      if (GetUserDetailsData?.data?.subscriptionType === "monthly" || GetUserDetailsData?.data?.subscriptionType == null) {
        setSelectedUserSubscriptionType("monthly");
      } else {
        setSelectedUserSubscriptionType("yearly");
      }
    }
  }, [GetUserDetailsData?.data]);

  useEffect(() => {
    if (HostawayLIstingsData !== "") {
      setListData(
        HostawayLIstingsData?.data?.filter((elem) => elem.status === "active")
      );
    }
  }, [HostawayLIstingsData]);


  useEffect(() => {
    dispatch(showCustomerCardListAPI());
  }, [])

  useEffect(() => {
    dispatch(GetUserDetailsApi());
  }, []);

  useEffect(() => {
    if (showCustomerCardListAPIData?.cardData?.length > 0 || showCustomerCardListAPIData?.cardData == null) {
      setCardDetails(showCustomerCardListAPIData?.cardData);
    }
  }, [showCustomerCardListAPIData]);

  let timer;

  const handlePayemntMethods = async () => {
    dispatch(setIsPaymentLoading(true));
    try {
      const createSubscriptionPayload = (settingSubscriptionYearlyPlan ? listData : selectedProperties)?.reduce((acc, item) => {
        acc.listingIds.push(item.id);
        acc.recordIds.push(item.recordId);
        return acc;
      }, {
        listingIds: [
          ...(autoUnautoPropertyListingData?.primaryListingsIdsForSubscription?.length > 0
            ? autoUnautoPropertyListingData.primaryListingsIdsForSubscription.map(id => parseInt(id))
            : selectedOrder.map(id => parseInt(id))),

          ...(freeProperty?.length > 0
            ? freeProperty.map(item => item.id) // Add listing IDs from freeProperty
            : []),

          ...(expiredListing?.length > 0
            ? expiredListing.map(item => item.id) // Add listing IDs from expiredListing
            : [])
        ],
        recordIds: [
          ...(primaryIdSubscription?.length > 0
            ? primaryIdSubscription.map(item => item.recordId)
            : selectedOrderrecordId),

          ...(freeProperty?.length > 0
            ? freeProperty.map(item => item.recordId) // Add record IDs from freeProperty
            : []),

          ...(expiredListing?.length > 0
            ? expiredListing.map(item => item.recordId) // Add record IDs from expiredListing
            : [])
        ],
        ...(!subscriptionSelectPlan ? { isAutomated: checked } : {}),
        subscriptionType: mainSubType,
        subscriptionPrice: mainConstAmount,
        ...(couponApply.applyCouponCode?.success && couponCode ? { couponId: couponCode } : {})

      });
      createSubscriptionPayload.listingIds = [...new Set(createSubscriptionPayload.listingIds)];
      createSubscriptionPayload.recordIds = [...new Set(createSubscriptionPayload.recordIds)];

      await dispatch(subscriptionPaymentAPI(createSubscriptionPayload))
        .unwrap()
      dispatch(setSubscriptionModalVisible(false));
      dispatch(setDisabledSidebarButton(true));
      navigate(LISTING);
      dispatch(setChecked(true));
      dispatch(setIsPropertyListingLoader(true));
      if (GetUserDetailsData?.data?.subscriptionType == null) {
        dispatch(insertUserSubscriptionType({ subscriptionType: selectedUserSubscriptionType }));
      }
      dispatch(GetUserDetailsApi());
      dispatch(setSubscriptionSelectPlan(false))

      if (settingSubscriptionYearlyPlan) {

        await dispatch(insertUserSubscriptionType({ subscriptionType: subscriptionYearlyItem?.subType })).unwrap()
        await dispatch(GetUserDetailsApi()).unwrap()
      }

      timer = setTimeout(() => {
        dispatch(HostawayLIstings());
        dispatch(setIsPropertyListingLoader(false));
        dispatch(setDisabledSidebarButton(false));
        clearTimeout(timer);
      }, 5000);
      setCouponCode("")
      dispatch(setIsPaymentLoading(false));
      dispatch(setExpiredListing([]));
      dispatch(setFreeProperty([]));
      dispatch(setSelectedUserSubscriptionStatus(false));
      dispatch(setSelectedProperties([]));
      dispatch(getAllCoupon());
      dispatch(setAutomationModalVisible(false));
      settoggleMY(true);
      setIsApplied(null);
      setCouponId(null);
      dispatch(setVisible(false));
      setIsShow(false);
      setSelectedRanks(0);
      setPrimaryIdSubscription([]);
      dispatch(setSubscriptionYearlyData(""))

    }
    catch (error) {
      dispatch(setIsPaymentLoading(false));
      dispatch(setDisabledSidebarButton(false));
      setIsPaymentStatus(true);
      setSubscriptionYearlyData("")
      setCouponCode("");
      Swal.fire({
        title: "Error",
        text: error || "An unexpected error occurred.",
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
        width: "300px",
        customClass: { popup: "small-swal" },
        backdrop: true,
        didOpen: () => {
          const swalPopup = document.querySelector(".swal2-container");
          if (swalPopup) {
            swalPopup.style.zIndex = "2000";
          }
        },
      });
    }
  };

  useEffect(() => {
    if (cardLength && autoUnautoPropertyListingData?.data) {
      if (couponCode === "" && !offerData?.remainingAmount) {
        dispatch(calculateTaxData({ amount: subscriptionYearlyItem?.subType === "yearly" ? parseInt(autoUnautoPropertyListingData?.data?.yearlyPrice) : selectedUserSubscriptionType === "yearly" ? parseInt(autoUnautoPropertyListingData?.data?.yearlyPrice) : parseInt(autoUnautoPropertyListingData?.data?.monthlyPrice) })).unwrap();
      }
    }
  }, [offerData, subscriptionYearlyItem, selectedUserSubscriptionType]);


  //Apply Coupon
  const handleApplyCoupon = async () => {
    if (!couponCode || !couponCode.trim()) {
      Swal.fire({
        title: "Error",
        text: "Please add coupon code first.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
        width: "300px",
        customClass: { popup: "small-swal" },
        backdrop: true,
        didOpen: () => {
          const swalPopup = document.querySelector(".swal2-container");
          if (swalPopup) {
            swalPopup.style.zIndex = "2000";
          }
        },
      });
      return;
    }

    const couponData = {
      couponCode,
      subscriptionType: subscriptionYearlyItem?.subType || GetUserDetailsData?.data?.subscriptionType || selectedUserSubscriptionType,
      subscriptionPrice: mainConstAmount || autoUnautoPropertyListingData?.data?.[GetUserDetailsData?.data?.subscriptionType === "monthly" || selectedUserSubscriptionType === "monthly" ? "monthlyPrice" : "yearlyPrice"]

    }

    try {

      const result = await dispatch(newCouponAppliedFn(couponData)).unwrap();
      if (result?.success && cardLength) {
        const resultTax = await dispatch(calculateTaxData({ amount: parseInt(result?.data?.remainingAmount) })).unwrap();
        Swal.fire({
          title: "Success",
          text: result?.message || "An unexpected error occurred.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          width: "300px",
          customClass: { popup: "small-swal" },
          backdrop: true,
          didOpen: () => {
            const swalPopup = document.querySelector(".swal2-container");
            if (swalPopup) {
              swalPopup.style.zIndex = "2000";
            }
          },
        });
      }
      setOfferData(result?.data || 0);
    } catch (error) {
      console.log(error, "err")
      const message = error.trim();
      Swal.fire({
        title: "Error",
        text: message || "This coupon code is not valid. Please apply valid coupon",
        icon: "error",
        timer: 4000,
        showConfirmButton: false,
        width: "300px",
        customClass: { popup: "small-swal" },
        backdrop: true,
        didOpen: () => {
          const swalPopup = document.querySelector(".swal2-container");
          if (swalPopup) {
            swalPopup.style.zIndex = "2000";
          }
        },
      });
      console.log("err", error)
    }
  }

  useEffect(() => {
    if (couponCode === "") {
      setOfferData("")
    }
  }, [couponCode])

  const applyCoupon = async (data) => {
    const getData = data;

    try {
      const response = await dispatch(
        couponApplied({
          promotionCodeId: getData?.stripePromotionCodeId,
          subscriptionType: selectedUserSubscriptionType,
          subscriptionPrice: subscriptionPrice?.totalAmount,
        })
      ).unwrap();

      if (response?.success) {
        setIsApplied(getData?.stripePromotionCodeId);
        setCouponId(getData?.id);
        setRemainingAmount(response?.data?.remainingAmount);
        setDiscountPrice(response?.data?.discountPrice);
        Swal.fire({
          title: "Success",
          text: response?.message || "An unexpected error occurred.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          width: "300px",
          customClass: { popup: "small-swal" },
          backdrop: true,
          didOpen: () => {
            const swalPopup = document.querySelector(".swal2-container");
            if (swalPopup) {
              swalPopup.style.zIndex = "2000";
            }
          },
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Coupon could not be applied. Please try again.",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
          width: "300px",
          customClass: { popup: "small-swal" },
          backdrop: true,
          didOpen: () => {
            const swalPopup = document.querySelector(".swal2-container");
            if (swalPopup) {
              swalPopup.style.zIndex = "2000";
            }
          },
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error || "An unexpected error occurred.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
        width: "300px",
        customClass: { popup: "small-swal" },
        backdrop: true,
        didOpen: () => {
          const swalPopup = document.querySelector(".swal2-container");
          if (swalPopup) {
            swalPopup.style.zIndex = "2000";
          }
        },
      });
      console.error(error);
    }
  };

  //Remove Coupon
  const removeCupon = async () => {
    Swal.fire({
      title: "Success",
      text: "Coupon removed successfully.",
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
      width: "300px",
      customClass: { popup: "small-swal" },
      backdrop: true,
      didOpen: () => {
        const swalPopup = document.querySelector(".swal2-container");
        if (swalPopup) {
          swalPopup.style.zIndex = "2000";
        }
      },
    });
    setIsApplied(null);
    setCouponId(null);
    setRemainingAmount(null);
    setDiscountPrice(null);
  }

  const resetCopunAmount = () => {
    setIsApplied(null);
    setCouponId(null);
    setRemainingAmount(null);
    setDiscountPrice(null);
  }

  const headerContent = (
    <h4 className="px-3 pt-3"> Subscription & Payment</h4>

  );

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="white-space-nowrap ">Add Payment Details</span>
    </div>
  );

  const deeleteCardConfirm = (data) => {
    confirmDialog({
      message: (
        <div className="mx-3 my-2 text-center">
          <h5 className="exDemiBold fs-2 text-center text-navy my-2">
            <i className="pi pi-info-circle" style={{ color: "rgb(241, 36, 36)" }}></i> &nbsp; Are you sure?</h5>
          <p className="text-navy-light text-center w-75 m-auto">
            Do you want to delete this Card?
          </p>
        </div>
      ),
      closable: false, // Add this line to prevent closing the dialog
      defaultFocus: "accept",
      accept: () => deleteCard(data),
      reject: () => deleteCardRejectBullet(),
    });
  };

  const deleteCardRejectBullet = () => { };

  const deleteCard = async (data) => {
    try {
      if (data) {
        await dispatch(deleteStripePaymentMethod({
          paymentMethodId: data,
        }))
          .unwrap()
        dispatch(deleteTempTax(true))
        dispatch(showCustomerCardListAPI());
      }
    } catch (error) {
      console.error("Error in deleteCard:", error);
    }
  };

  useEffect(() => {
    dispatch(getAllCountryNpmData());
  }, [dispatch]);


  return (
    <>
      <Dialog
        header={headerContent}
        visible={subscriptionModalVisible}
        style={{ width: "30vw", borderRadius: "30px" }}
        closable={!isPaymentLoading} // The dialog will only be closable when isPaymentLoading is false
        onHide={() => {
          if (!subscriptionModalVisible) return;
          dispatch(setSubscriptionModalVisible(false));
          setRemainingAmount(null);
          setDiscountPrice(null);
          setIsPaymentStatus(false);
          setOfferData("");
          setCouponCode("");
          dispatch(setSubscriptionSelectPlan(false));
          dispatch(setSubscriptionYearlyData(""));
          settoggleMY(false);
          dispatch(setChecked(true));
          setSelectedUserSubscriptionType(GetUserDetailsData?.data?.subscriptionType || "monthly");
        }}

      >
        <div className="content p-3">

          {
            GetUserDetailsData?.data?.subscriptionType == null ?
              (
                <div className="my-switch text-center mb-4">
                  <span
                    className={"monthlyTab " + (!toggleMY ? "active" : "")}
                    onClick={() => handleTabClick(false, "monthly")}
                  >
                    Monthly
                  </span>
                  <span
                    className={"monthlyTab " + (toggleMY ? "active" : "")}
                    onClick={() => handleTabClick(true, "yearly")}
                  >
                    Yearly
                  </span>
                </div>

              ) : (

                <div className="my-switch text-center mt-3 mb-4">
                  {settingSubscriptionYearlyPlan ? (
                    // If subscriptionYearlyItem.subType is "yearly", show the Yearly tab
                    <span
                      className={`monthlyTab active ${!toggleMY ? "active" : ""}`}
                      style={{
                        pointerEvents: "none" // Disable pointer events for the Yearly tab
                      }}
                    >
                      Yearly
                    </span>
                  ) : (
                    // If not yearly, check if the subscription is monthly or null
                    (GetUserDetailsData?.data?.subscriptionType === "monthly") ? (
                      <span
                        className={`monthlyTab active ${toggleMY ? "active" : ""}`}
                        style={{
                          pointerEvents: GetUserDetailsData?.data?.subscriptionType === "monthly" ? "none" : "pointer"
                        }}
                      >
                        Monthly
                      </span>
                    ) : (
                      // Otherwise, show Yearly tab if it's not "monthly"
                      <span
                        className={`monthlyTab active ${!toggleMY ? "active" : ""}`}
                        style={{
                          pointerEvents: GetUserDetailsData?.data?.subscriptionType === "yearly" ? "none" : "pointer"
                        }}
                      >
                        Yearly
                      </span>
                    )
                  )}
                </div>
              )
          }

          <div className="align-items-center d-flex justify-content-center price-currency">
            <div className="price d-flex flex-column-reverse">
              <h5 className={`${offerData?.remainingAmount ? "discount-price mb-0 " : "fw-bold"}  "fw-bold m-0 me-2 text-dark"`}>
                {mainConstAmount}
              </h5>

              <h4 className={"fw-bold m-0 me-2 text-dark"}>
                {offerData ? `${offerData?.remainingAmount}` : ""}
              </h4>

            </div>
            <div className="currency">
              <select name="" id="" className="usddropdown  color-black dash_card card">
                <option value="USD" selected>
                  $ USD
                </option>
              </select>
            </div>
          </div>

          <div className="text-center my-2">

            {
              autoUnautoPropertyListingData?.existingListingPrice && (
                GetUserDetailsData?.data?.subscriptionType === "monthly" || selectedUserSubscriptionType === "monthly" ?
                  <p>
                    {
                      autoUnautoPropertyListingData?.existingListingPrice
                        ? `$ ${autoUnautoPropertyListingData?.existingListingPrice} (Current subscription)`
                        : ""
                    }
                    {
                      autoUnautoPropertyListingData?.data?.monthlyPrice !== undefined &&
                        autoUnautoPropertyListingData?.existingListingPrice !== undefined
                        ? ` + $ ${autoUnautoPropertyListingData?.data?.[GetUserDetailsData?.data?.subscriptionType === "monthly" || selectedUserSubscriptionType === "monthly" ? "monthlyPrice" : "yearlyPrice"]}
                        (New listings)`
                        : ""
                    }
                  </p>
                  :
                  <p>
                    {
                      autoUnautoPropertyListingData?.existingListingPrice
                        ? `$ ${autoUnautoPropertyListingData?.existingListingPrice} (Current subscription)`
                        : ""
                    }
                    {
                      autoUnautoPropertyListingData?.data?.yearlyPrice !== undefined &&
                        autoUnautoPropertyListingData?.existingListingPrice !== undefined
                        ? ` + $ ${autoUnautoPropertyListingData?.data?.[GetUserDetailsData?.data?.subscriptionType === "monthly" || selectedUserSubscriptionType === "monthly" ? "monthlyPrice" : "yearlyPrice"]}
                          (New listings)`
                        : ""
                    }
                  </p>
              )
            }

          </div>

          <i class="fa-solid fa-gift"></i>
          <div className="gift-coupan-input mt-3 mb-4">
            <input
              type="text"
              className="form-control GiftCouponInput "
              placeholder="Gift coupon"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button onClick={handleApplyCoupon} disabled={couponApply?.isLoading} >
              {couponApply?.isLoading ? 'Applying..' : couponCode ? (offerData ? 'Applied' : 'Apply') : 'Apply'}
            </button>
          </div>

          {isLoading ? <div className="text-center"><Spinner /></div> :
            <div className={`coupon-summary ${(offerData || taxAmount) ? 'p-2 card' : ''}`}>
              {/* If only taxAmountExclusive exists, show only tax */}
              {taxAmount?.success && !(couponCode && offerData) ? (
                <>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span>Total Amount:</span>
                    <span>$ {mainConstAmount}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span>Tax:</span>
                    <span>$ {taxAmountExclusive}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span>Total Payable:</span>
                    <span>$ {finalAmountForTax}</span>
                  </div>
                </>
              ) : ""}
              {/* If both offerDataAmount and taxAmountExclusive exist, show both coupon discount and tax */}
              {(offerData && couponCode) && taxAmount?.success ? (
                <>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span>Total Amount:</span>
                    <span>$ {mainConstAmount}</span>

                  </div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span>Coupon Discount:</span>
                    <span>$ {offerDataAmount}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span>Tax:</span>
                    <span>$ {taxAmountExclusive}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span>Total Payable:</span>
                    <span>$ {finalAmountForTaxWithCoupon}</span>
                  </div>
                </>
              ) : ""}

              {/* If only offerDataAmount exists, show only coupon discount */}
              {(offerData && couponCode) && !taxAmount?.success ? (
                <>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span>Total Amount:</span>
                    <span>$ {mainConstAmount}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <span>Coupon Discount:</span>
                    <span>$ {offerDataAmount}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span>Total Payable:</span>
                    <span>$ {finalAmountForCoupon}</span>
                  </div>
                </>
              ) : ""}
            </div>
          }

          <p className="payment-subscription">
          <i className="pi pi-info-circle fs-6 text-secondary"></i> Your first payment will be adjusted based on the number of days remaining in this month.
          </p>

          {
            cardDetails?.length > 0 ? (
              cardDetails?.map((card) => (
                <div className="card mb-3 p-0 mt-2 " key={card?.card?.exp_month}>
                  <div className="card-body  p-0">
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={process.env.PUBLIC_URL + "/Assets/images/credit-card-color-icon.png"}
                          style={{ height: "45px", width: "45px" }}
                          alt="Customer Card"
                        />
                        <div>
                          <h6 className="card-text">
                            {card?.card?.brand?.toUpperCase()} **** **** **** {card?.card.last4}
                          </h6>
                          <p className="card-text mb-0">
                            Expiry {card?.card?.exp_month}/{card?.card?.exp_year}
                          </p>
                        </div>
                      </div>
                      <div className="payact-btn">
                        <span onClick={() => deeleteCardConfirm(card?.id)}>
                          <i className="pi pi-trash p-2 m-1" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="card mb-3 p-0 mt-2">
                <div className="card-body p-0">

                  <div className={`d-flex justify-content-between align-items-center p-2 ${isPaymentStatus === true ? 'error_card' : ''}`}>
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={process.env.PUBLIC_URL + "/Assets/images/credit-card-color-icon.png"}
                        style={{ height: "45px", width: "45px" }}
                        alt="Customer Card"
                      />
                      <div>
                        <h4 className="card-text">
                          **** **** ****
                        </h4>
                        <p className="card-text mb-0">
                          Expiry MM/YYYY
                        </p>
                      </div>
                    </div>
                    <div className="payact-btn">
                      <span onClick={() => dispatch(setCardVisible(true))}>
                        <i className="pi pi-plus p-2 m-1" />
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            )
          }

          <div className="features-table">
            <table class="table dash_card card ">
              <thead>
                <tr>
                  <th className="dash_card card  color-black d-flex" style={{ boxShadow: "unset !important" }}>Features</th>
                  <th className="text-center dash_card card  color-black" style={{ boxShadow: "unset !important" }}>Automated</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="dash_card card  color-black" style={{ boxShadow: "unset !important" }}>AI Listing Optimization on Airbnb</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>
                  </td>
                </tr>
                <tr>
                  <th className="dash_card card  color-black" style={{ boxShadow: "unset !important" }}>Dynamic Updates</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>
                  </td>
                </tr>
                <tr>
                  <th className="dash_card card  color-black" style={{ boxShadow: "unset !important" }}>PMS Integrations</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>

                  </td>
                </tr>
                <tr>
                  <th className="dash_card card  color-black" style={{ boxShadow: "unset !important" }}>Support</th>
                  <td className="text-center dash_card card" style={{ boxShadow: "unset !important" }}>
                    <i className="pi pi-check" style={{ color: 'green' }}></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="action-buttons_n">
            <button
              disabled={isPaymentLoading}
              onClick={() => {
                if (!subscriptionModalVisible) return;
                dispatch(setSubscriptionModalVisible(false));
                setRemainingAmount(null);
                setDiscountPrice(null);
                setIsPaymentStatus(false);
                setOfferData("");
                setCouponCode("");
                dispatch(setSubscriptionSelectPlan(false));
                dispatch(setSubscriptionYearlyData(""));
                settoggleMY(false);
                dispatch(setChecked(true));
                setSelectedUserSubscriptionType(GetUserDetailsData?.data?.subscriptionType || "monthly");
              }}>
              Skip
            </button>

            <button
              disabled={isPaymentLoading}
              onClick={handlePayemntMethods}
            >
              {isPaymentLoading ? (
                <span
                  className="spinner-border spinner-border-sm me-2 text-dark"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Pay Now"
              )}
            </button>
          </div>
        </div>
      </Dialog>


      <Dialog
        className="p-3 background-white rounded-17 addpayment-dialog"
        visible={cardVisible}
        modal
        header={headerElement}
        style={{ maxWidth: "500px", minWidth: "400px" }}
        onHide={() => {
          if (!cardVisible) return;
          dispatch(setCardVisible(false));
          dispatch(setAutomationModalVisible(false));

        }}
      >
        <ToastContainer />
        <Elements stripe={stripePromise}>
          <SaveCardDetails
            newAmountTax={newAmountTax}
            getAllCountryNpmDataData={getAllCountryNpmDataData}
          />
        </Elements>
      </Dialog>
    </>
  );
}

export default SubscriptionPaymentModal;
