import React, { useEffect, useState } from "react";
import { Form, ErrorMessage, Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextError from "../../Components/TextError";
import { Dialog } from "primereact/dialog";
import { HostawayAPISchema } from "../../Utils/Validation/Validation";
import { HostawayAuth } from "../../Redux/Features/authReducer/authSlice";
import { useNavigate } from "react-router-dom";
import { LISTING } from "../../Routes/Constant";
import { setFirstTimeOpen } from "../../Redux/Features/listReducer/listSlice";
import { setHostawayModalVisible } from "../../Redux/Features/stateReducer/stateSlice";

function Hostway() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [initialValues, setInitialValues] = useState({
    hostawayAccountId: "",
    hostawayAccount_secret: "",
  });
  const [fieldType, setFieldType] = useState({
    key: "password",
    token: "password",
  });

  const { CheckHoatawayConnectionData } = useSelector((state) => state.Auth);
  const { hostawayModalVisible } = useSelector((state)=>state.State);

  useEffect(() => {
    if (CheckHoatawayConnectionData?.status) {
      const hostawayConnection = CheckHoatawayConnectionData?.validConnection?.find(item => item?.dataSource === 'hostaway');
      if (hostawayConnection) {
        setInitialValues({
          hostawayAccountId: hostawayConnection?.clientId,
          hostawayAccount_secret: hostawayConnection?.clientSecret,
        });
      }
    }
  }, [CheckHoatawayConnectionData]);

  function handleFieldFocus(value, value2) {
    if ("in" === value && "key" === value2) {
      setFieldType({ key: "text", token: "password" });
    } else if ("in" === value && "token" === value2) {
      setFieldType({ key: "passwprd", token: "text" });
    } else {
      setFieldType({ key: "password", token: "password" });
    }
  }
  const onSubmit = async (values) => {
    let timer;
    setLoading(true); // Start loading indicator
    const data = {
      client_id: values.hostawayAccountId,
      client_secret: values.hostawayAccount_secret,
    };
    try {
     const  results =  await dispatch(HostawayAuth(data))
        .unwrap()
        .then((result) => {
          if (result?.status) {
            timer = setTimeout(() => {
              navigate(LISTING);
              dispatch(setFirstTimeOpen(true));
              dispatch(setHostawayModalVisible(false));
              clearTimeout(timer);
            }, 1000)
          }
        })
        .finally(() => {
          setLoading(false); // Stop loading indicator
        });
        console.log(results,"results")
    }
    catch (error) {
      setLoading(false);
    };
  };

  const HostawayHeader = (
    <>
      <h4 className="color-black exMedium mb-3">Connect to Hostaway Account</h4>
    </>
  );

  return (
    <>
      <Dialog
        className="p-4 background-white rounded-17 mx-3 listing-setting"
        header={HostawayHeader}
        visible={hostawayModalVisible}
        style={{ maxWidth: "550px", width: "100%" }}
        onHide={() => {
          if (!hostawayModalVisible) return;
          dispatch(setHostawayModalVisible(false));
        }}
      >
        <div id="hostaway_connection">
          <div id="wrapper">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={HostawayAPISchema}
              onSubmit={onSubmit}
            >
              <Form>
                <div className="row py-4 mx-2 aligndiv">
                  <div className="col-md-6 px-0">
                    <label for="hostawayAccountId" className="exMedium">
                      Account ID <span className="strictly">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 px-0">
                    <Field
                      type={fieldType.key}
                      className="w-100 form-control form-control-lg input-lg rounded-pill color-dark "
                      id="hostawayAccountId"
                      placeholder="Enter Hostaway API Key"
                      name="hostawayAccountId"
                      onFocus={(e) => {
                        handleFieldFocus("in", "key");
                      }}
                      onBlur={(e) => {
                        handleFieldFocus("out", "key");
                      }}
                    />
                    <ErrorMessage name="hostawayAccountId" component={TextError} />

                  </div>
                </div>
                <div className="row border-bottom py-4 mx-2">
                  <div className="col-md-6 px-0">
                    <label for="hostawayAccount_secret" className="exMedium">
                      Account Token <span className="strictly">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 px-0">
                    <Field
                      type={fieldType.token}
                      className="w-100 form-control form-control-lg input-lg rounded-pill color-dark "
                      id="hostawayAccount_secret"
                      placeholder="Enter Hostaway API Token"
                      name="hostawayAccount_secret"
                      onFocus={(e) => {
                        handleFieldFocus("in", "token");
                      }}
                      onBlur={(e) => {
                        handleFieldFocus("out", "key");
                      }}
                    />
                    <ErrorMessage name="hostawayAccount_secret" component={TextError}
                    />
                  </div>
                </div>

                {CheckHoatawayConnectionData &&
                  CheckHoatawayConnectionData?.status &&
                  CheckHoatawayConnectionData?.validConnection?.find(item => item?.dataSource === 'hostaway')
                  ? (
                    <div className="login-card-bottom mt-4 text-end">
                      <button
                        type="submit"
                        className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                        onClick={() => navigate("/admin/dashboard")}
                      >
                        Go back to Dashboard
                      </button>
                    </div>
                  ) : (
                    <div className="login-card-bottom mt-4 text-end">
                      <button
                        type="submit"
                        className="btn btn-lg button-purple rounded-pill ms-auto mt-2"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm me-2 text-dark"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Connect Hostaway"
                        )}
                      </button>
                    </div>
                  )}
              </Form>
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Hostway;
