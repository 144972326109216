import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectSubscriptionSchema } from "../../../Utils/Validation/Validation";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../../Components/TextError/index";
import { GetUserDetailsApi } from "../../../Redux/Features/authReducer/authSlice";
import { insertUserSubscriptionType } from "../../../Redux/Features/dashboard/dashboardSlice";
import { setSubscriptionModalVisible, setSubscriptionSelectPlan, setSubscriptionYearlyData } from "../../../Redux/Features/stateReducer/stateSlice";
import { subscriptionForPaidAndExpiredListings } from "../../../Redux/Features/listReducer/listSlice";
import { calculateTaxData } from "../../../Redux/Features/subscriptionReducer/subscriptionSlice";

const FormWrapper = ({ loading, setFieldValue, radioDisabled }) => {
    const { GetUserDetailsData } = useSelector((state) => state.Auth);

    useEffect(() => {
        if (GetUserDetailsData?.data?.subscriptionType) {
            setFieldValue("subscriptionType", GetUserDetailsData?.data?.subscriptionType)
        }
    }, [GetUserDetailsData])

    return (
        <Form className="px-2">
            <div className="d-flex align-items-md-center justify-content-between py-3 border-bottom flex-md-row flex-column">
                <div>
                    <h4 className="fs-22 exMedium">Subscription Plan</h4>
                    <p className="fs-14 text-navy-light exLight mb-2 mb-md-0">
                        View and update your subscription plan.
                    </p>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn btn-lg button-navy rounded-pill mb-md-2"
                        disabled={GetUserDetailsData?.data?.subscriptionType === "yearly" || loading}                    >
                        {loading ? (
                            <span
                                className="spinner-border spinner-border-sm me-2 text-dark"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            "Save Changes"
                        )}
                    </button>
                    <ErrorMessage name="subscriptionType" component={TextError} />
                </div>
            </div>
            <div className="p-3">
                <div className="inputlabel flex align-items-center mb-3">
                    <Field
                        style={{ cursor: GetUserDetailsData?.data?.subscriptionType === "yearly" ? "not-allowed" : "auto" }}

                        type="radio"
                        name="subscriptionType"
                        value="monthly"
                        disabled={radioDisabled}
                    />
                    <label className="text-lg ml-2 gp-5">
                        Monthly
                    </label>
                </div>
                <div className="inputlabel flex align-items-center">
                    <Field
                        type="radio"
                        name="subscriptionType"
                        value="yearly"
                    />
                    <label className="text-lg ml-2 gp-5">
                        Yearly
                    </label>
                </div>
            </div>
        </Form>
    )
}

function SubscriptionPlan() {
    const dispatch = useDispatch();
    const { GetUserDetailsData, HostawayLIstingsData } = useSelector((state) => state.Auth);
    const [loading, setLoading] = useState(false);
    const { showCustomerCardListAPIData } = useSelector((state) => state.Subscription);
    const cardLength = showCustomerCardListAPIData?.cardData?.length > 0

    const [radioDisabled, setRadioDisabled] = useState(false);
    const [listData, setListData] = useState([]);


    useEffect(() => {
        dispatch(GetUserDetailsApi());
    }, []);

    useEffect(() => {
        if (GetUserDetailsData?.data?.subscriptionType === "yearly") {
            setRadioDisabled(true);
        }
    }, [GetUserDetailsData?.data?.subscriptionType]);

    useEffect(() => {
        if (HostawayLIstingsData !== "") {
            setListData(
                HostawayLIstingsData?.data?.filter((elem) => elem.status === "active")
            );
        }
    }, [HostawayLIstingsData]);

    const initialValues = {
        subscriptionType: GetUserDetailsData?.data?.subscriptionType || ""
    };
    const handleSubmit = async (values) => {

        setLoading(true);
        const data = {
            subscriptionType: values.subscriptionType,
        };
        try {
            if (values?.subscriptionType === "yearly" && listData?.length > 0 && GetUserDetailsData?.data?.subscriptionType=="monthly") {
                const newData = await dispatch(subscriptionForPaidAndExpiredListings({ propertyListingIds: listData.map(item => item.id) }))
                    .unwrap();
                if (newData.success && cardLength) {
                    const resultTax = await dispatch(calculateTaxData({ amount: values.subscriptionType === "yearly" ? parseInt(newData?.data?.yearlyPrice) : parseInt(newData?.data?.monthlyPrice) })).unwrap();
                    dispatch(setSubscriptionYearlyData({ ...newData, subType: 'yearly' }))
                }
                dispatch(setSubscriptionModalVisible(true));
                dispatch(setSubscriptionSelectPlan(true));
            }
            else {
                await dispatch(insertUserSubscriptionType(data)).unwrap()
                await dispatch(GetUserDetailsApi()).unwrap()
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <div
            className="tab-pane fade shadow-sm mt-4 rounded-17"
            id="subscription"
            role="tabpanel"
            aria-labelledby="subscription-tab"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={SelectSubscriptionSchema}
                onSubmit={handleSubmit}

            >
                {(formProps) => {
                    return (
                        <FormWrapper {...formProps} loading={loading} radioDisabled={radioDisabled} />
                    )
                }}
            </Formik>
        </div>
    );
}
export default SubscriptionPlan;
