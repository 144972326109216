import React, { useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { formatedDateTime } from "../../../Utils/constant/commonFunction";
import { getUser } from "../../../Redux/Features/LocalStorage";

import { useDispatch, useSelector } from "react-redux";
import { markAllReadApi } from "../../../Redux/Features/notificationReducer/notificationSlice";
import { arrayBufferToBase64 } from "../../../Utils/constant/commonFunction";
import { subscriptionForPaidAndExpiredListings } from "../../../Redux/Features/listReducer/listSlice";
import { setSubscriptionModalVisible, setExpiredListing, setSubscriptionSelectPlan, setSelectPlanLoader } from "../../../Redux/Features/stateReducer/stateSlice";
import { calculateTaxData } from "../../../Redux/Features/subscriptionReducer/subscriptionSlice";

function Notification(props) {
  const { data, userDetailsData,cardLength } = props;
  const User = getUser();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const subscriptionType = userDetailsData?.data?.subscriptionType

  const { activeProperty, freeProperty, expiredListing, disabledSidebarButton, selectPlanLoader } = useSelector((state) => state.State);
  const { HostawayLIstingsData } = useSelector((state) => state.Auth);
  const { subType } = useSelector((state) => state.commonState);

  useEffect(() => {
    if (activeProperty?.length > 0) {
      dispatch(setExpiredListing(
        activeProperty.filter((elem) => elem.isPaid === false)
      ));
    }
  }, [activeProperty]);


  const notificationLength = data?.filter((item) => !item.isSeen);

  const markAllAsRead = () => {
    dispatch(markAllReadApi(data?.map((item) => item.id)));
  };

  const redirectToPage = (recordId) => {
    localStorage.setItem("EditdID", recordId);
    navigate("/propertydetails");
  };

  window.onload = () => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark");
    }
  };

  const automateMoreListings = async (data) => {
    dispatch(setSelectPlanLoader(true));
    try {
      if (data?.length > 0) {
        const newData = await dispatch(subscriptionForPaidAndExpiredListings({ propertyListingIds: data.map(item => item.id) })).unwrap();
        if (newData?.success && cardLength) {
          const resultTax = await dispatch(calculateTaxData({ amount: subscriptionType === "yearly" ? parseInt(newData?.data?.yearlyPrice) : parseInt(newData?.data?.monthlyPrice) })).unwrap();
        }
        dispatch(setSubscriptionModalVisible(true));
        dispatch(setSubscriptionSelectPlan(true));
        dispatch(setSelectPlanLoader(false));

      }
    }
    catch (error) {
      console.log(error);
      dispatch(setSubscriptionSelectPlan(false));
      dispatch(setSelectPlanLoader(false));

    }
  }

  return (
    <>
      {
        expiredListing?.length > 0 && User?.roleId === 2 ? (
          location.pathname === "/admin/listing" ?
            (
              <div className="SubescritpionPopUPWrapper">
                <div>
                  <p className="m-0">Subscription : Not Selected</p>
                  <p className="m-0" style={{ fontSize: "12px" }}>
                    Select the subscription plan to unlock full AutoRank features
                  </p>
                </div>
                <div>

                  <button
                    type="submit"
                    className={`SelectPlanBtn bg-white text-dark ${selectPlanLoader ? 'maxWidthSelect' : ''}`}
                    disabled={selectPlanLoader || disabledSidebarButton} // Disable button while loading
                    onClick={() => automateMoreListings(expiredListing)}
                  >
                    {selectPlanLoader ? (
                      <span
                        className="spinner-border spinner-border-sm me-2 text-dark"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Select Plan"
                    )}
                  </button>

                </div>
              </div>
            ) : ""
        ) : (
          location.pathname === "/admin/listing" && User?.roleId === 2 && activeProperty?.length > 0 && activeProperty?.length <= 5 && HostawayLIstingsData?.paidListing === false ? (
            <div className="AutomateMoreWrapper">
              <div>
                <p className="m-0">Automate more listings</p>
                <p className="m-0">
                  Consider adding more listings to maximize your account's potential!
                </p>
              </div>
              <div>

                <button
                  type="submit"
                  className={`SelectPlanBtn bg-white text-dark ${selectPlanLoader ? 'maxWidthSelect' : ''}`}
                  disabled={selectPlanLoader || disabledSidebarButton} // Disable button while loading
                  onClick={() => automateMoreListings(freeProperty)}
                >
                  {selectPlanLoader ? (
                    <span
                      className="spinner-border spinner-border-sm me-2 text-dark"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Select Plan"
                  )}
                </button>
              </div>
            </div>
          ) : ""
        )
      }

      <ul className="d-flex align-items-center ms-auto p-0 mb-2 mb-lg-0">
        <li className="nav-item list-group dropdown pt-2 notify-dropdown">
          <button
            className="nav-link border-end border-muted icons"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="icon-outer display-center  ms-3 me-md-5 me-3 purple-15 position-relative">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/images/icons/notification_icon.svg"
                }
                alt=""
              />
              <div className="notification-number text-white color-purple rounded-circle display-center">
                {notificationLength?.length}
              </div>
            </div>
          </button>
          <div
            className="dropdown-menu dropdown-menu-end p-0 border-0 shadow-sm rounded-17 mt-2"
            aria-labelledby="navbarDropdown"
          >
            <div className="d-flex align-items-center justify-content-between purple-15 px-3 py-3">
              <h5 className="mb-0 me-auto exMedium">Notifications</h5>
              <div className="">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/Assets/images/icons/check_all.svg"
                  }
                  alt=""
                />
                <button
                  className="mark-read btn btn-link text-decoration-none text-purple ps-1 "
                  onClick={markAllAsRead}
                >
                  Mark all as read
                </button>
              </div>
              <li>
                <hr className="dropdown-divider m-0 text-purple" />
              </li>
            </div>
            {
              <ul className="list-unstyled">
                {data?.map((item, index) => (
                  <>
                    <div
                      className={`${item?.isSeen == 1
                        ? "dropdown-item d-flex align-items-center justify-content-between py-3 text-decoration-none bg-notification"
                        : "dropdown-item d-flex align-items-center justify-content-between py-3 text-decoration-none bg-not-unread"
                        }`}
                      onClick={() =>
                        item.recordId && redirectToPage(item.recordId)
                      }
                    >
                      <div className="notify-msg position-relative ps-4">
                        <p className="text-wrap mb-0">
                          {item.notificationMessage}
                        </p>
                        <span>
                          <small className="color-muted">
                            {formatedDateTime(item.updatedAt)}
                          </small>
                        </span>
                      </div>
                      <img
                        src={
                          item?.webNotificationThamnail
                            ? item?.webNotificationThamnail
                            : arrayBufferToBase64(item?.webUserThamnail?.data)
                        }
                        onError={(e) => {
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/Assets/images/house_placeholder.png"; // replace with a default image
                        }}
                        alt=""
                      />
                    </div>
                    <li>
                      <hr className="dropdown-divider m-0 color-muted" />
                    </li>
                  </>
                ))}
              </ul>
            }
            <div className="purple-15 text-center w-100">
              <NavLink
                to={"/notification"}
                className=" text-purple py-3 exMedium text-center text-decoration-underline d-block"
                href="allNotifications.html"
              >
                View all notifications{" "}
              </NavLink>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
}

export default Notification;
