import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import {
  BASE_URL,
  GET_UPDATE_LOGS,
  GET_QUEUE_JOB_LOGS,
  GET_TICKETMASTER_LOGS
} from "../../../Config/Config"

// Fetch get UpdateLogs  API
const getUpdateLogs = async (data) => {
  const response = await axios.post(
    BASE_URL + GET_UPDATE_LOGS,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

// Fetch get QueueJobLogs  API
const getQueueJobLogs = async (data) => {
  const response = await axios.post(
    BASE_URL + GET_QUEUE_JOB_LOGS,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

// Fetch get Ticketmaster Logs  API
const getTicketMasterLogs = async (data) => {
  const response = await axios.post(
    BASE_URL + GET_TICKETMASTER_LOGS,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

const logService = {
  getUpdateLogs,
  getQueueJobLogs,
  getTicketMasterLogs
}

export default logService;