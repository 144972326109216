import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { formatedDateTime } from "../../Utils/constant/commonFunction";
import { arrayBufferToBase64 } from "../../Utils/constant/commonFunction";
import { useDispatch } from "react-redux";
import { getNotificationInactiveByUser, fetchNotificationApi } from "../../Redux/Features/notificationReducer/notificationSlice";
import { useNavigate } from "react-router-dom";

function AllNotification(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, handleMarkAllAsRead } = props;
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  //Mark as read function call
  const markAllAsRead = () => {
    handleMarkAllAsRead(data.map((item) => item.id));
  };

  //Delete Notification List
  const deleteAllNotification = (id) => {
    let timer;
    if (id) {
      dispatch(getNotificationInactiveByUser({ primaryId: id }))
        .unwrap()
        .then((result) => {
          if (result.success) {
            timer = setTimeout(() => {
              dispatch(fetchNotificationApi())
              clearTimeout(timer);
            }, 1000);
          }
        })
    }
  }
  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <h5 class="exMedium fs-22 color-black">Most Recent</h5>
        {/* <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField> */}
      </div>
    );
  };

  const renderFooter = (data) => {
    return (
      <button onClick={markAllAsRead} class="btn text-purple btn-purple-outline">
        Mark all as read
      </button>
    );
  };

  const redirectToPage = (recordId) => {
    localStorage.setItem('EditdID', (recordId)); // Store data as a string
    navigate("/propertydetails");
  };

  const rowBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div class="col-12 allread cstm-drk-bg">
          <div class={`${rowData?.isSeen == 1 ? 'card mb-2 border-0 border-bottom rounded-0 bg-notification ' : 'card mb-2 border-0 border-bottom rounded-0 cstm-unread'}`}
            onClick={() => rowData.recordId && redirectToPage(rowData.recordId)}
          >
            <div class="card-body d-flex align-items-center ">
              <img className="notifyImage"
                src={
                  rowData?.webNotificationThamnail
                    ? rowData?.webNotificationThamnail
                    :
                    arrayBufferToBase64(rowData?.webUserThamnail?.data)
                }
                onError={(e) => {
                  e.target.src = process.env.PUBLIC_URL + "/Assets/images/house_placeholder.png"; // replace with a default image
                }}

                alt=""
              />
              <div class="w-100">
                <div class="d-flex align-items-center justify-content-between mt-4">
                  <p class="card-text color-muted fs-14 mb-0 ms-2">
                    {rowData.notificationMessage}
                  </p>
                  <p class="card-text mb-0 text-end">
                    <small class="color-muted">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/Assets/images/icons/clock.svg"
                        }
                        alt=""
                        class="clock-icon me-2"
                      />
                      {formatedDateTime(rowData.updatedAt)}
                    </small>
                  </p>
                </div>
              </div>
              <button
                type="button"
                class="btn-close position-absolute position-absolute top-0 end-0 m-3"
                aria-label="Close"
                onClick={() => deleteAllNotification(rowData?.id)}
              ></button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const header = renderHeader();
  const footer = renderFooter();

  return (
    <DataTable
      value={data}
      dataKey="id"
      tableStyle={{ minWidth: "50rem" }}
      responsiveLayout="scroll"
      size="small"
      showGridlines
      removableSort
      paginator
      rows={5}
      paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      filters={filters}
      filterDisplay="row"
      globalFilterFields={["notificationMessage", "updatedAt"]}
      header={header}
      footer={footer}
      emptyMessage="No Notification found."
    >
      <Column field="notificationMessage" body={rowBodyTemplate}></Column>
    </DataTable>
  );
}

export default AllNotification;
