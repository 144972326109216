import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  serachShowHide: false,
  profileShowHide: false,
  subType: null,
};

const commonStateSlice = createSlice({
  name: "commonState",
  initialState,
  reducers: {
    handleSearchFunction: (state, { payload }) => {
      state.serachShowHide = payload;
    },
    handleProfileFunction: (state, { payload }) => {
      state.profileShowHide = payload;
    },
    handleSubscriptionType:(state,{payload})=>{
      state.subType = payload
    }
  },
});

export const { handleSearchFunction, handleProfileFunction ,handleSubscriptionType} =
  commonStateSlice.actions;

export default commonStateSlice.reducer;
