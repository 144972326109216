import { createSlice } from '@reduxjs/toolkit'

export const stateSlice = createSlice({
  name: 'state',
  initialState: {
    couponPopup: false,
    proSubscriptionModel: false,
    visible: false,
    paymentModalOpen: false,
    subscriptionModalVisible: false,
    subscriptionYearlyItem: "",
    subscriptionSelectPlan: false,
    guestyModalVisible: false,
    hostawayModalVisible: false,
    automationModalVisible: false,
    userSelectedPlanStatus: false,
    userSelectedSubscriptionListing: {},
    selectedProperties: [],
    freeListingAddButtonLoading: false,
    saveCardButtonLoading: false,
    resetGuestyTokenModalVisible: false,
    disabledButton: false,
    activeProperty: [],
    freeProperty: [],
    expiredListing: [],
    isPropertyListingLoader: false,
    cardVisible: false,
    checked: true,
    isPaymentLoading: false,
    disabledSidebarButton: false,
    selectPlanLoader: false,
  },
  reducers: {
    setCouponPopup: (state, action) => {
      state.couponPopup = action.payload
    },
    setProSubscriptionModel: (state, action) => {
      state.proSubscriptionModel = action.payload;
    },
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
    setSubscriptionModalVisible: (state, action) => {
      state.subscriptionModalVisible = action.payload;
    },
    setSubscriptionYearlyData: (state, {payload}) => {
      state.subscriptionYearlyItem = payload;
    },
    setSubscriptionSelectPlan: (state, action) => {
      state.subscriptionSelectPlan = action.payload;
    },
    setGuestyModalVisible: (state, action) => {
      state.guestyModalVisible = action.payload;
    },
    setHostawayModalVisible: (state, action) => {
      state.hostawayModalVisible = action.payload;
    },
    setAutomationModalVisible: (state, action) => {
      state.automationModalVisible = action.payload;
    },
    setPaymentModalOpen: (state) => {
      state.paymentModalOpen = true
    },
    setPaymentModalClose: (state) => {
      state.paymentModalOpen = false
    },
    applyCoupon(state) {
      state.couponPopup = false
      state.paymentModalOpen = true
    },

    setSelectedPlanListing(state, action) {
      state.userSelectedPlanStatus = true;
      state.userSelectedSubscriptionListing = action.payload;
    },
    setSelectedProperties(state, action) {
      state.selectedProperties = action.payload;
    },

    setFreeListingAddButtonLoading: (state, action) => {
      state.freeListingAddButtonLoading = action.payload;
    },
    setSaveCardButtonLoading: (state, action) => {
      state.saveCardButtonLoading = action.payload;
    },
    setResetGuestyTokenModalVisible: (state, action) => {
      state.resetGuestyTokenModalVisible = action.payload;
    },

    setDisabledButton: (state, action) => {
      state.disabledButton = action.payload;
    },
    setActiveProperty: (state, action) => {
      state.activeProperty = action.payload;     
    },
    setFreeProperty: (state, action) => {
      state.freeProperty = action.payload;     
    },
    setExpiredListing: (state, action) => {
      state.expiredListing = action.payload;     
    },
    setIsPropertyListingLoader: (state, action) => {
      state.isPropertyListingLoader = action.payload;
    },
    setCardVisible: (state, action) => {
      state.cardVisible = action.payload;
    },
    setChecked: (state, action) => {
      state.checked = action.payload;
    },
    setIsPaymentLoading: (state, action) => {
      state.isPaymentLoading = action.payload;
    },
    setDisabledSidebarButton: (state, action) => {
      state.disabledSidebarButton = action.payload;
    },
    setSelectPlanLoader: (state, action) => {
      state.selectPlanLoader = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCouponPopup, setProSubscriptionModel, setVisible, setSubscriptionModalVisible,setSubscriptionSelectPlan, setGuestyModalVisible, setHostawayModalVisible, setAutomationModalVisible, setPaymentModalOpen, setPaymentModalClose, applyCoupon, setSelectedPlanListing,
  setSelectedProperties, setFreeListingAddButtonLoading, setSaveCardButtonLoading, setResetGuestyTokenModalVisible, setDisabledButton, setActiveProperty, setSubscriptionPlanModalVisible, setFreeProperty,
  setExpiredListing, setIsPropertyListingLoader, setCardVisible,setSubscriptionYearlyData, setChecked,
  setIsPaymentLoading, setDisabledSidebarButton, setSelectPlanLoader} = stateSlice.actions;

export default stateSlice.reducer