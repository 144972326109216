import axios from "axios";
import { BASE_URL } from "./Config";
import { getToken } from "../Redux/Features/LocalStorage";
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
    (req) => {
        const token = getToken();

        if (token) {
            req.headers['Authorization'] = `Bearer ${token}`;
        }
        return req;

    },
    (error) => Promise.reject(error)
)
axiosInstance.interceptors.response.use(
    (response) => response,

    async (error) => {
        
        if (!error.response.data.success) {
            Cookies.remove("otamiserToken");
            Cookies.remove("otamiserToken2");
            localStorage.clear();
        }

        return Promise.reject(error);
    }
)
export default axiosInstance
