import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import {
  BASE_URL,
  PURCHASE_SUBSCRIPTIONS,
  DOWNLOAD_INVOICE
} from "../../../Config/Config"

// Fetch purchased subscriptions
const getPurchasedSubscriptions = async () => {
  const response = await axios.get(
    BASE_URL + PURCHASE_SUBSCRIPTIONS,
    HeaderToken(getToken())
  );
  return response?.data;
};

// Download Customer Payment Invoice
export const downloadInvoice = async (data) => {
  try {
      const response = await axios.get(
          BASE_URL + DOWNLOAD_INVOICE,
          HeaderToken(getToken2() ? getToken2() : getToken())
      );
      return response?.data;
  } catch (error) {
      return;
  }
};

const settingService = {
  getPurchasedSubscriptions,
  downloadInvoice
}
export default settingService;