import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successToast, errorToast } from "../../../Utils/constant/commonFunction";
import dashboardService from "./dashboardService"

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    insertUserSubscriptionTypeData:"",
    userDashBoardsData:""
}

// Fetch Insert User Subscription Type
export const insertUserSubscriptionType = createAsyncThunk(
    "insertUserSubscriptionType",
    async (data, thunkAPI) => {
        try {
            return await dashboardService.insertUserSubscriptionType(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); errorToast(message);
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Fetch User DashBoard Data
export const userDashBoardData = createAsyncThunk(
    "userDashBoardData",
    async (data, thunkAPI) => {
        try {
            return await dashboardService.userDashBoardData(data);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message)
        }
    }
)

/*  For Slice */
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        reset: (state) => {
            state.isSuccess = false;
            state.isError = false;
            state.isLoading = false;
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
                 // Insert User Subscription Type
                 .addCase(insertUserSubscriptionType.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(insertUserSubscriptionType.fulfilled, (state, { payload }) => {
                    state.isError = false;
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.message = payload.message;
                    successToast(payload.message);
                    state.insertUserSubscriptionTypeData = payload;
    
                })
                .addCase(insertUserSubscriptionType.rejected, (state, { payload }) => {
                    state.isError = true;
                    state.isLoading = false;
                    state.isSuccess = false;
                    state.message = payload.message;
                })

                 // Fetch User DashBoard Data
                 .addCase(userDashBoardData.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(userDashBoardData.fulfilled, (state, { payload }) => {
                    state.isError = false;
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.message = payload.message;
                    state.userDashBoardsData = payload;
    
                })
                .addCase(userDashBoardData.rejected, (state, { payload }) => {
                    state.isError = true;
                    state.isLoading = false;
                    state.isSuccess = false;
                    state.message = payload.message;
                })
    },
});

export const { reset } = dashboardSlice.actions;
export default dashboardSlice.reducer;