import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { setCouponPopup, setPaymentModalClose } from '../../Redux/Features/stateReducer/stateSlice';
import PaymentForm from './PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from 'primereact/dialog';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const PaymentModal = () => {
    const stripePromise = loadStripe("pk_test_51M8NqfSEb6g6VjFy6gZp5y1wWv5m7Ure9gRQbNH3gEHrC9NCIUrDDQxLQ0gDAWREo0P1ZIXq8B3RrZrUq5KFaRAO00IBZYkxJM"); // Replace with your actual public key
    const [Paymentvisible, setPaymentVisible] = useState(false);
    const dispatch = useDispatch();
    const { paymentModalOpen } = useSelector((state) => state.State);
  

    useEffect(() => {
        if (!paymentModalOpen) return null;
        setPaymentVisible(paymentModalOpen);
    }, []);


    const onHide = () => {
        dispatch(setPaymentModalClose());
    };

    return (
        <Dialog
            visible={Paymentvisible}
            style={{ width: '25vw',height:"auto", padding:"10px 10px" }}
            onHide={onHide}
            icon="pi pi-exclamation-triangle"
            acceptClassName="p-button-primary"
            rejectClassName="p-button-secondary"
            acceptLabel="Close"
            rejectLabel="Cancel"
        >
            <Elements stripe={stripePromise}>
                <PaymentForm />
            </Elements>
        </Dialog>

    );
};

export default PaymentModal;
