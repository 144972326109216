import React, { useEffect, useState } from "react";
import Header from "../../Layouts/HeaderPage";
import SideBar from "../../Layouts/SideBar";
import CreateCoupon from "./CreateCoupon";
import CouponList from "./CouponList";
import { useDispatch, useSelector } from "react-redux";
import { getCouponApi } from "../../Redux/Features/couponReducer/couponSlice";
import { GetUserListApi } from "../../Redux/Features/authReducer/authSlice";
import SendCoupon from "./SendCoupon";
import { getUser } from "../../Redux/Features/LocalStorage";

const Coupon = () => {

    const dispatch = useDispatch();
    const { getCouponApiData, getAllCouponData } = useSelector((state) => state.Coupon);
    const { GetUserListData } = useSelector((state) => state.Auth);
    const userData = getUser();
    const getCouponData = Array.isArray(getCouponApiData?.data) ? getCouponApiData.data : [];
    const GetUserData = Array.isArray(GetUserListData?.data) ? GetUserListData.data : [];
    const [activeTab, setActiveTab] = useState('couponList'); // Default active tab

    useEffect(() => {
        dispatch(getCouponApi());
        dispatch(GetUserListApi());
        if (userData?.roleId === 1) {
            setActiveTab('createCoupon')
        } else {
            setActiveTab('couponList')
        }
    }, []);

    const handleTabChange = (event) => {
        if (event.target.id === 'couponList-tab') {
            dispatch(getCouponApi());
        }
    };

    return (
        <>
            <div id="setting_page">
                <Header />
                <div id="wrapper">
                    <SideBar />
                    <section id="content-wrapper">
                        <div class="welcome-user">
                            <h2 className=" content-title mb-0 exDemiBold mb-2">Discount Coupon</h2>
                        </div>
                        <ul className="nav nav-tabs mb-3" id="settingTab" role="tablist">
                            {userData?.roleId === 1 && (
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'createCoupon' ? 'active' : ''}`}
                                        id="createCoupon-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#createCoupon"
                                        type="button"
                                        role="tab"
                                        aria-controls="createCoupon"
                                        aria-selected={activeTab === 'createCoupon'}
                                        onClick={(event) => handleTabChange(event, 'createCoupon')}
                                    >
                                        Create Coupon
                                    </button>
                                </li>
                            )}

                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18  ${activeTab === 'couponList' ? 'active' : ''}`}
                                    id="couponList-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#couponList"
                                    type="button"
                                    role="tab"
                                    aria-controls="couponList"
                                    aria-selected={activeTab === 'couponList'}
                                    onClick={(event) => handleTabChange(event, 'couponList')}
                                >
                                    Coupon List
                                </button>
                            </li>
                            {userData?.roleId === 1 && (
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link color-muted px-3 pb-2 border-0 exMedium fs-18 ${activeTab === 'sendCoupon' ? 'active' : ''}`}
                                        id="sendCoupon-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#sendCoupon"
                                        type="button"
                                        role="tab"
                                        aria-controls="sendCoupon"
                                        aria-selected={activeTab === 'sendCoupon'}
                                        onClick={(event) => handleTabChange(event, 'sendCoupon')}
                                    >
                                        Send Coupon
                                    </button>
                                </li>
                            )}
                        </ul>
                        <div className="tab-content" id="couponTabContent">
                            {/* <!-- ----------------------CreateCoupon tab content----------------------------- --> */}
                            {userData?.roleId === 1 && (
                                <CreateCoupon activeTab={activeTab} />
                            )}

                            {/* <!-- ----------------------CouponList tab content----------------------------- --> */}
                            <CouponList
                                getAllCouponData={getAllCouponData}
                                activeTab={activeTab}
                            />
                            {/* <!-- ----------------------Send coupon tab content----------------------------- --> */}
                            {userData?.roleId === 1 && (
                                <SendCoupon
                                    getUsersAPIData={GetUserData}
                                    getAllCouponData={getAllCouponData}
                                    activeTab={activeTab}
                                />
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Coupon;
