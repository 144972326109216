import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'

const ProgressLoader = () => {
    return (
        <div className="listing-loader-overlay">
            <div className="listing-loader">
                <ProgressSpinner
                    style={{ height: "50px" }}
                    strokeWidth="3"
                    animationDuration="0.8s"
                />
            </div>
        </div>
    )
}

export default ProgressLoader
