import axios from "axios";
import { getToken, getToken2 } from "../LocalStorage";
import { HeaderToken } from "../../../Utils/constant/commonFunction";
import { 
    BASE_URL, 
    INCBULLETPOINTS,
    GETCOUNTRIES,
    PROPERTY_BY_ID,
    AUTO_UNAUTO_PROPERTY_LISTING,
    USER_ACTIVATEPROPERTY,
    GET_ALL_EVENT,
    GET_TURIST_ATTRACTION_DATA,
    GET_USER_AUDIT_LOG_DATA_OF_PROPERTY_LISTING_ID,
    GET_CITY,
    LATITUDE_LOGNITUDE_OF_LISTINGS,
    CONNECTION_DATA_FOR_ADMIN,
    GET_TAGS,
    GET_ALL_COUNTRY_NPM_DATA,
    GET_LISTING_SETTING_OF_ID,
    SUBSCRIPTION_PAYMENT,
    SUBSCRIPTION_FOR_PAID_AND_EXPIRED_LISTINGS,
    GET_STATE_BY_COUNTRY_CODE
} from "../../../Config/Config";

// Include bullet points API
const incBulletPoints = async (data) => {
    const response = await axios.post(
      BASE_URL + INCBULLETPOINTS,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

// Fetch country list
const getCountries = async (data) => {
    const response = await axios.get(
      BASE_URL + GETCOUNTRIES,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  //Get Listing Data on Behalf of id
  const getPropertyById = async (data) => {
    const response = await axios.post(
      BASE_URL + PROPERTY_BY_ID,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  
  //Get Property Listing Setting Data on Behalf of id
  const getListingSettingOfId = async (data) => {
    const response = await axios.post(
      BASE_URL + GET_LISTING_SETTING_OF_ID,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  //Automate and Unautomate property listing
  const autoUnautoPropertyListing = async (data) => {
    const response = await axios.post(
      BASE_URL + AUTO_UNAUTO_PROPERTY_LISTING,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

    //user activate property listing
    const userActivateProperty = async (data) => {
      const response = await axios.post(
        BASE_URL + USER_ACTIVATEPROPERTY,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken())
      );
      return response?.data;
    };

  //Event Listing Data API
  const getAllEventsOnBasisOFCity = async (data) => {
    const response = await axios.post(
      BASE_URL + GET_ALL_EVENT,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

   //get turist Attration Data API
   const getturistAttrationData = async (data) => {
    const response = await axios.post(
      BASE_URL + GET_TURIST_ATTRACTION_DATA,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };


  //Get User Audit Lo Data Of Property Listing Id
  const getUserAuditLogDataOfPropertyListingId = async (data) => {
    const response = await axios.post(
      BASE_URL + GET_USER_AUDIT_LOG_DATA_OF_PROPERTY_LISTING_ID,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  // Get List Of City on behalf of Country
const getCity = async (data) => {
  const response = await axios.post(
    BASE_URL + GET_CITY,
    data,
    HeaderToken(getToken2() ? getToken2() : getToken())
  );
  return response?.data;
};

  // Fetch Get List Of Latitude and Longitude of Listings
  const latitudeLognitudeOfListings = async (data) => {
    const response = await axios.get(
      BASE_URL + LATITUDE_LOGNITUDE_OF_LISTINGS,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  // Get List Of Hostaway Connected Users List
const connectionDataForAdmin = async (data) => {
  const response = await axios.post(
    BASE_URL + CONNECTION_DATA_FOR_ADMIN,
    data,
    HeaderToken(getToken())
  );
  return response?.data;
};

  // Fetch Get List Of Tags
  const gettags = async () => {
    const response = await axios.get(
      BASE_URL + GET_TAGS,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

// Fetch Get All Country Npm Data
  const getAllCountryNpmData = async () => {
    const response = await axios.get(
      BASE_URL + GET_ALL_COUNTRY_NPM_DATA,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };
  const getStateByCountryCode = async (data) => {
    const response = await axios.post(
      BASE_URL + GET_STATE_BY_COUNTRY_CODE, data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  export const subscriptionPaymentAPI = async (data) => {
      const response = await axios.post(
        BASE_URL + SUBSCRIPTION_PAYMENT,
        data,
        HeaderToken(getToken2() ? getToken2() : getToken())
      );
      return response?.data;  
  };

  //Subscription For Paid And Expired Listings
  const subscriptionForPaidAndExpiredListings = async (data) => {
    const response = await axios.post(
      BASE_URL + SUBSCRIPTION_FOR_PAID_AND_EXPIRED_LISTINGS,
      data,
      HeaderToken(getToken2() ? getToken2() : getToken())
    );
    return response?.data;
  };

  const listService = {
    incBulletPoints,
    getCountries,
    getPropertyById,
    getListingSettingOfId,
    autoUnautoPropertyListing,
    userActivateProperty,
    getAllEventsOnBasisOFCity,
    getturistAttrationData,
    getUserAuditLogDataOfPropertyListingId,
    getCity,
    latitudeLognitudeOfListings,
    connectionDataForAdmin,
    gettags,
    getAllCountryNpmData,
    subscriptionPaymentAPI,
    subscriptionForPaidAndExpiredListings,
    getStateByCountryCode
  }

  export default listService;