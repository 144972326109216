import React from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { setAutomationModalVisible, setSubscriptionModalVisible, setChecked } from "../../Redux/Features/stateReducer/stateSlice";
import { calculateTaxData } from "../../Redux/Features/subscriptionReducer/subscriptionSlice";
import { errorToast } from "../../Utils/constant/commonFunction";

const PropertyListingModal = (props) => {

  const { handleAddList, selectedOrder, freelistingdata, autoUnautoPropertyData, userSubscriptionType } = props
  const { automationModalVisible, freeListingAddButtonLoading, disabledButton, checked } = useSelector((state) => state.State);
  const { showCustomerCardListAPIData } = useSelector((state) => state.Subscription);
  const cardLength = showCustomerCardListAPIData?.cardData?.length > 0
  const dispatch = useDispatch();

  const handleCheckbox = (e) => {
    const { checked } = e?.target;
    dispatch(setChecked(checked));
  }


  const callhandleAddList = async () => {

    if (cardLength) {
      const result = await dispatch(calculateTaxData({ amount: userSubscriptionType === "yearly" ? parseInt(autoUnautoPropertyData?.yearlyPrice) : parseInt(autoUnautoPropertyData?.monthlyPrice) })).unwrap();

    }

    if (!freelistingdata == 0) {
      if (selectedOrder?.length <= `${freelistingdata}`) {
        handleAddList(checked);
      }
      else {
        dispatch(setAutomationModalVisible(false));
        if (autoUnautoPropertyData?.monthlyPrice) {
          dispatch(setSubscriptionModalVisible(true));
        }
        else {
          errorToast("Something went wrong.")
        }
      }
    }
    else {
      dispatch(setAutomationModalVisible(false));
      if (autoUnautoPropertyData?.monthlyPrice) {
        dispatch(setSubscriptionModalVisible(true));
      }
      else {
        errorToast("Something went wrong.")
      }
    }
  }
    const footerContent = (
      <div className="d-flex px-4 justify-content-between my-2" style={{ padding: "25px" }}>
        <div className="d-flex align-items-end justify-content-center inputcheck">
          <input
            className="form-check-input green-checkbox"
            type="checkbox"
            checked={checked}
            aria-label="..."
            onChange={(e) => handleCheckbox(e)}
          />

          <label className="mx-2">Automate Selected Listings</label>
        </div>

        <button
          className="btn button-purple rounded-pill font-normal me-4"
          disabled={freeListingAddButtonLoading || disabledButton}
          onClick={callhandleAddList}
        >
          {freeListingAddButtonLoading ? (
            <span
              className="spinner-border spinner-border-sm me-2 text-dark"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Add Listing"
          )}
        </button>

      </div>
    );

    const headerContent = (
      <div className="SmartListing">
        <h4> Smart Listing Automation</h4>
      </div>
    )

    return (
      <>
        <div className="">
          <Dialog
            header={headerContent}
            visible={automationModalVisible}
            style={{ width: "36vw", fontSize: "0.9rem" }}
            onHide={() => {
              if (!automationModalVisible) return;
              dispatch(setAutomationModalVisible(false));
              dispatch(setChecked(true));

            }}
            footer={footerContent}
          >
            <div style={{ padding: "25px" }}>
              <p className="m-0">
                AutoRank leverages AI to enhance the visibility, performance, and
                appeal of your listings. Here's how it attracts more customers and
                increases views & bookings:
              </p>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <ul className="dotted-list">
                  <li className="d-flex mb-2">
                    <i
                      className="pi pi-circle-fill"
                      style={{
                        fontSize: "5px",
                        marginTop: "9px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    <p className="SmartListingbold me-2">
                      <span className="spanname"> Automated Keyword Optimization - </span>
                      Ensures listings are packed with high-impact keywords to
                      boost search visibility.
                    </p>
                  </li>

                  <li className="d-flex mb-2">
                    <i
                      className="pi pi-circle-fill"
                      style={{
                        fontSize: "5px",
                        marginTop: "9px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    <p className="SmartListingbold me-2">
                      {" "}
                      <span> Personalized Descriptions - </span>
                      Crafts tailored descriptions that resonate with potential
                      guests or buyers, creating a stronger appeal.
                    </p>
                  </li>
                  <li className="d-flex mb-2">
                    <i
                      className="pi pi-circle-fill"
                      style={{
                        fontSize: "5px",
                        marginTop: "9px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    <p className="SmartListingbold me-2">
                      <span className="SmartListingbold">Competitor Tracking -</span>
                      Monitors top competing listings, allowing you to stay ahead
                      of trends and adapt to changes in listing demand.
                    </p>
                  </li>
                </ul>
              </div>
              <p>
                These automated features help you stay competitive and drive
                bookings with minimal manual effort.
              </p>
            </div>
          </Dialog>
        </div>
      </>
    );
  };

  export default PropertyListingModal;
