import React, { useState } from "react";
import 'jspdf-autotable';
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";
import { DateFormat } from "../../../Utils/constant/commonFunction";

function PastInvoices(props) {
  const { downloadInvoiceData } = props;
  const [isLoading, setIsLoading] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };


  const renderHeader = () => {
    return (
      <div className="">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {

    const handleInvoiceDownload = (rowData) => {
      setIsLoading(true)
      const link = document.createElement('a');
      link.href = rowData.receiptUrl;
      link.download = 'invoice.pdf';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false)
    };

    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-download"
          onClick={() => handleInvoiceDownload(rowData)}
          isLoading={isLoading}
          className="fw-bolder"
          rounded
          outlined
          severity="secondary"
        />
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (varient) => {
    return (
      <Tag
        value={varient?.status?.charAt(0)?.toUpperCase() + varient?.status?.slice(1)}
        className={
          varient?.status === "paid"
            ? "mint-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
            : varient?.status === "unpaid"
              ? "red-10 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
              : "lime-30 color-dark fw-light rounded-pill fs-14 px-3 py-2 exMono"
        }
      ></Tag>
    );
  };

  const header = renderHeader();

  return (
    <div
      className="tab-pane fade shadow-sm mt-4 rounded-17"
      id="pastInvoices"
      role="tabpanel"
      aria-labelledby="pastInvoices-tab"
    >
      <DataTable
        value={downloadInvoiceData || []}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        responsiveLayout="scroll"
        size="small"
        showGridlines
        removableSort
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown  PrevPageLink CurrentPageReport NextPageLink "
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        filters={filters}
        filterDisplay="row"
        globalFilterFields={["invoiveNumber", "status", "date", "amount"]}
        header={header}
        emptyMessage="No Invoices Found"
      >
        <Column
          field="id"
          header="Invoice Id"
          className="exMono text-center"
          sortable
        ></Column>

        <Column
          field="amount"
          header="Amount"
          className="exMono text-center"
          sortable
        ></Column>

        <Column
          field="currency"
          header="Currency"
          className="exMono text-center"
          sortable
        ></Column>

        <Column
          field="status"
          header="Status"
          sortable
          className="text-center"
          body={statusBodyTemplate}
        ></Column>

        <Column
          field="created"
          header="Create At"
          className="exMono text-center"
          sortable
          body={(row) => <span>{DateFormat(row?.created)}</span>}
        ></Column>

        <Column
          header="Action"
          body={actionBodyTemplate}
          className="text-center"
        ></Column>

      </DataTable>
    </div>
  );
}
export default PastInvoices;
